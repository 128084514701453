import * as React from 'react';
import logo from 'images/logo_blanco.svg';


const PoliticasCondiciones = () => {
    return (
        <div className="cont_principal politicas_condiciones">

            <div className="cont_parrafo">

                <img src={logo} alt="logo" />

                <div className="politicas">
                    <h3>Politica de Privacidad</h3>
                    <p>
                        En cumplimiento de la Ley Estatutaria 1581 de 2012 y
                        sus decretos reglamentarios, por medio de la aceptación
                        al presente documento, el USUARIO y el ARTISTA autorizan
                        a TU SERENATA el tratamiento de sus datos personales de
                        acuerdo con las finalidades y demás condiciones establecidas
                        en la POLITICA DE PRIVACIDAD, la cual, declaran conocer y
                        aceptar. Es de anotar que en la política de privacidad,
                        además de las finalidades se encuentran los derechos del
                        USUARIO y del ARTISTA en cuanto al tratamiento de sus datos
                        personales y la manera de ejercer los mismos frente a la compañía.
                    </p>

                    <h3>USO DE LA INFORMACIÓN </h3>

                    <p> TU SERENATA se reserva el derecho al uso de la información
                        recolectada en el registro al sistema y la información generada
                        o derivada de la utilización de la app. La información de
                        registro se usa esencialmente para facilitar la comunicación
                        y contacto de TU SERENATA, y entre el USUARIO y ARTISTA.
                        La información personal suministrada por el USUARIO a TU SERENATA
                        cuando solicita un evento musical, es entregada únicamente al
                        ARTISTA que ha contrato para cumplir con el servicio musical
                        adquirido, o en el caso de solicitar una serenata para ya, la
                        información es publicada en la sección LA PLAYA a los ARTISTAS
                        del género musical y ciudad que cumplen con los requerimientos
                        solicitados por el USUARIO para el cumplimiento de su evento.
                    </p>

                    <h3>RESPONSABILIDADES DE TU SERENATA</h3>

                    <p>TU SERENATA, NO es responsable por la confiabilidad y/o calidad
                        del servicio de la serenata o lo que ocurra en ella. Esto incluye
                        timo, demora, incumplimiento de las partes contractuales, además
                        de la no aceptación del servicio por parte del USUARIO, o cancelación
                        del contrato por alguna de las partes, y todos los aspectos
                        sustanciales a la hora de contratar una serenata. <br /> <br />
                        Se recomienda que tanto el USUARIO como el ARTISTA que NO estén de
                        acuerdo con alguno de los puntos expuestos en el presente documento,
                        se abstengan de descargar y/o usar la aplicación. En el mercado
                        existe una gran variedad de opciones para solicitar el mismo servicio
                        que ofrece TU SERENATA, TU SERENATA es sólo una más. Descargar y/o
                        utilizar TU SERENATA significa aceptar todos los términos y condiciones
                        incluidos en el presente documento. <br /> <br />
                        TU SERENATA se reserva el derecho a modificar los términos y condiciones
                        del presente documento. Las modificaciones surtirán efecto y se notificaran
                        tan pronto como sean publicadas al público. Es deber del USUARIO y del ARTISTA
                        conocer los términos y condiciones de uso así como las modificaciones que
                        se hagan a los términos y condiciones de uso, y en caso de no estar de
                        acuerdo, suspender el uso de TU SERENATA y borrarlo de su aparato electrónico.

                    </p>


                    <h3>EL SERVICIO ES PARA USO PERSONAL </h3>
                    <p>El USUARIO y el ARTISTA sólo pueden usar TU SERENATA para su uso personal.
                        El USUARIO y la contraseña con las cuales se identifica cada uno ante
                        TU SERENATA son de uso personal e intransferible. El USUARIO y el ARTISTA
                        se comprometen a guardar dicha información en un lugar seguro y a NO
                        compartirlo con ningún tercero. Es responsabilidad del USUARIO y del ARTISTA,
                        prevenir que su identidad sea utilizada por un tercero para realizar o adquirir
                        servicios con fines lícitos o ilícitos
                    </p>

                    <h3>POLÍTICA DE LOCALIZACIÓN DESDE LOS MÓVILES</h3>
                    <p> La privacidad de los USUARIOS y los ARTISTAS, es muy importante para nosotros.
                        TU SERENATA usa la localización actual del dispositivo electrónico, para
                        mejorar la experiencia en el mapa. Esta puede ser almacenada y compartida;
                        además, el USUARIO y ARTISTA siempre podrán activar o desactivar el servicio
                        de localización en la opción de configuración del menú de su teléfono. Si tienen
                        alguna inquietud sobre este tema, no dude en contactarnos a info@tu-serenata.com
                        o soporte@tu-serenata.com.
                    </p>

                </div>


                <div className="condiciones" id="terminos">

                    <h3>Términos & Condiciones</h3>
                    <p>El presente documento fija las pautas, condiciones y términos en los que el
                        USUARIO y el GRUPO MUSICAL, también llamado (ARTISTA), pueden acceder al
                        servicio provisto por TU SERENATA, también llamada en este documento
                        (app, sistema, plataforma digital, herramienta digital). La utilización
                        del servicio por parte del USUARIO, y la publicación y promoción de sus
                        servicios musicales por parte del ARTISTA, son una declaración formal por
                        parte de los mismos de la aceptación y conocimiento de todos los términos y
                        condiciones incluidas en el presente documento.
                    </p>

                    <h3>ACLARACIÓN DE NO PRESTACIÓN DE SERVICIOS MUSICALES</h3>
                    <p>TU SERENATA NO provee y tampoco realiza servicios musicales de ningún tipo.
                        Simplemente le da al USUARIO una herramienta digital, que le permite ponerse
                        en contacto y/o contratar por medio de la misma, a un ARTISTA que ha subido
                        información de interés público sobre su agrupación musical en TU SERENATA,
                        que sí provee servicios musicales y que efectivamente cumple con la documentación,
                        pautas y parámetros de admisión requeridos por el sistema TU SERENATA. <br /> <br />
                        TU SERENATA, NO es responsable por la calidad ni el cumplimiento de los servicios
                        musicales provistos por el ARTISTA registrado en la app. <br /> <br />
                        TU SERENATA, NO tiene acuerdos con ningún ARTISTA. Simplemente es una herramienta
                        digital, en la cual un USUARIO puede comunicarse y/o contratar con cualquier ARTISTA
                        que este promocionando sus servicios musicales a través del sistema TU SERENATA.
                    </p>

                    <h3>CAPACIDAD PARA ENTRAR EN CONTRATOS LEGALES</h3>
                    <p>El USUARIO y el ARTISTA, NO pueden utilizar el sistema TU SERENATA para entrar en
                        otro tipo de contratos legales fuera de los mencionados en este documento.
                        TU SERENATA sólo es una herramienta digital que sirve como medio de
                        comunicación entre ARTISTAS y USUARIOS que desean cotizar y/o contratar los
                        servicios musicales directos de cada ARTISTA admitido por TU SERENATA.
                        El servicio es un contrato en el que sólo intervienen el USUARIO y el ARTISTA directamente.
                    </p>

                    <h3>DEBERES Y OBLIGACIONES DEL USUARIO Y DEL ARTISTA</h3>
                    <p> Es obligación tanto del  USUARIO como del ARTISTA, descargar la versión adecuada
                        y actualizada de la aplicación TU SERENATA para su dispositivo móvil, ya que de
                        esto depende que cada uno pueda acceder al servicio que ofrece la app. <br /> <br />
                        TU SERENATA, NO se hace responsable de los efectos que puedan sobrevenir al USUARIO
                        o al ARTISTA en su dispositivo electrónico por descargar la versión incorrecta o
                        inapropiada para su equipo electrónico. <br /> <br />
                        El USUARIO y el ARTISTA, aceptan que al contratar o aceptar el contrato de un
                        servicio musical a través de TU SERENATA, están entrando en una relación
                        contractual vinculante con la contraparte que lo solicita o que lo acepta;
                        con todas las implicaciones, obligaciones, deberes y derechos que contempla la ley. <br /> <br />
                        El USUARIO y el ARTISTA deben contar con la información de su dispositivo electrónico,
                        adecuada y actualizada, como en el caso de la fecha y la hora; para no tener contratiempos,
                        ni malos entendidos con la contraparte en la ejecución del contrato realizado por medio de TU SERENATA.

                    </p>

                    <h3>ADMISION DE LA APP</h3>
                    <p>TU SERENATA se reserva el derecho de admisión de los USUARIOS y ARTISTAS, que solicitan utilizar el servicio que ofrece la aplicación. Por lo cual, TU SERENATA en la ejecución de cumplir con su derecho requiere que: El ARTISTA al adquirir un perfil por medio de la aplicación declara: <br /> <br />
                        1) que NO es información ni publicidad falsa lo que está publicando a través de TU SERENATA, <br /> <br />
                        2) que está capacitado para prestar el servicio que el USUARIO necesita cotizarle y/o contratarle a través de la plataforma digital, <br /> <br />
                        3) que cumple con todos los requisitos señalados por TU SERENATA, como lo son: ser mayor de edad según la ley del país en el que TU SERENATA se encuentre presente prestando su servicio, nombre completo del representante, indicar el tipo de documento, Nº del documento, Nº de teléfono de contacto, ciudad donde va a estar disponible para cumplir con los servicios musicales que soliciten los USUARIOS a través de TU SERENATA, género musical de la agrupación, RUT de la agrupación musical o de su representante. En caso de que este último sea del representante, deberá ser el mismo que se mencionó inicialmente en la solicitud. <br /> <br />
                        4) que está de acuerdo con los términos y condiciones que pauta TU SERENATA para el correcto funcionamiento del sistema. Del mismo modo, el USUARIO al cotizar y/o contratar a un ARTISTA por medio de la herramienta digital declara: 1) que está enterado de las recomendaciones que realiza TU SERENATA para contratar una agrupación musical por medio de la misma, para su evento. 2) que NO utilizara la plataforma digital para generar incomodidad o molestias de cualquier tipo a la contraparte con la cual está entrando en contrato directo, a través de TU SERENATA, 3) que está de acuerdo con los términos y condiciones que pauta TU SERENATA para el correcto funcionamiento del sistema.</p>

                    <h3> MEDIOS DE PAGO</h3>
                    <p> TU SERENATA, en su objetivo de brindar un servicio competente, permite que cada USUARIO y ARTISTA, a través de la herramienta digital, puedan realizar compras utilizando los siguientes medios de pago:</p>

                    <h3>COMPRA DE SERVICIOS MUSICALES DENTRO DE LA PLATAFORMA DIGITAL</h3>
                    <p>El USUARIO puede comprar el servicio musical que ofrece el ARTISTA, utilizando los medios de pago electrónico o dinero en efectivo según el acuerdo previo al que hayan llegado las dos partes. Al elegir como medio de pago el electrónico, el USUARIO deberá: <br /><br />
                        1) confirmar que el ARTISTA también maneje este método de pago en la plataforma digital, <br /><br />
                        2) suministrar los datos que solicita el sistema, para realizar la compra a través del procesador de pagos que utiliza TU SERENATA. <br /> <br />
                        Cabe aclarar que TU SERENATA, NO se hace responsable de: <br /> <br />
                        1) la calidad y cumplimiento del servicio del ARTISTA o que esto conlleve a la solicitud de devolución de dineros por inconformidad. <br /><br />
                        2) inconvenientes con la tarjeta electrónica a la hora de realizar el pago con el ARTISTA, ya que para este caso el USUARIO deberá comunicarse directamente con la empresa que provee los servicios de la tarjeta.
                    </p>

                    <h3>CREDITOS PUBLICITARIOS PARA ARTISTAS</h3>
                    <p>Para utilizar el servicio que ofrece TU SERENATA a los ARTISTAS, estos mismos deberán comprar créditos. (Obtén más información sobre como comprar créditos, en las guías del ARTISTA que se encuentran publicadas en nuestro sitio web www.tu-serenata.com). Cada crédito tiene un valor de $6.000 COP y le permitirá al ARTISTA: <br /> <br />
                        1) Publicar información sobre la descripción de su servicio musical, redes sociales, videos, imágenes, números de contacto y trayectoria. <br /> <br />
                        2) Su información podrá estar visible al público en la plataforma digital durante un tiempo no inferior a 24 horas a partir de la activación del crédito. Sin embargo, este tiempo puede expirar debido a las serenatas que el sistema le genere al ARTISTA. Esto quiere decir que, si un USUARIO realiza un contrato por medio de TU SERENATA dentro del tiempo en que se encuentra activo el crédito, TU SERENATA automáticamente desactivara este crédito como comisión por el servicio musical, que le acaba de generar al ARTISTA, y de esta manera, el tiempo de visibilidad del ARTISTA dentro de la plataforma digital habrá finalizado, hasta que se repita el proceso nuevamente. <br /> <br />
                        3) Obtener una agenda de eventos programada y confiable. <br /> <br />
                        4) Recibir una calificación y/o comentario por su servicio musical. <br /> <br />
                        5) Obtener una recompensa en puntos, que a su vez podrá redimir. (Para más información, consulta sobre nuestro sistema de puntos en nuestro sitio web, www.tu-serenata.com en la opción sobre nosotros/sistema de puntos, premios y sanciones).
                    </p>

                    <h3>ARTISTAS DESTACADOS</h3>
                    <p>DESTACADOS es una sección de TU SERENATA, ubicada en la parte superior del resultado de la búsqueda realizada, por medio de la función RESERVA CON TIEMPO. Esta función le permite al USUARIO conocer, cuales son las agrupaciones musicales que han tenido un buen desempeño en TU SERENATA, y que pueden ser de interés para la asistencia de su evento. Para hacer parte de este servicio, el ARTISTA debe tener en cuenta lo siguiente: 1) Este servicio solo se puede adquirir por medio de puntos otorgados por TU SERENATA. <br /> <br />
                        2) Estos puntos solo se pueden cambiar por paquetes de visualizaciones. <br /> <br />
                        3) Cada paquete tiene un valor de 100 puntos, y equivale a 30 visualizaciones. <br /> <br />
                        4) Cada visualización expira, cada vez que el USUARIO hace clic sobre la agrupación musical que se encuentra resaltada en la sección DESTACADOS para conocer su servicio musical. <br /> <br />
                        5) Cada visualización tiene una duración de diez (10) segundos y está rotando según el orden de registro de la compra en el sistema. <br /> <br />
                        6) Las visualizaciones en la sección DESTACADOS, solo se podrán ver únicamente si el ARTISTA tiene activos créditos publicitarios normales en su cuenta.
                    </p>

                    <h3>CREDITOS DEL SERVICIO PREMIUM PARA ARTISTAS</h3>
                    <p>Para acceder al servicio Premium que ofrece TU SERENATA, el ARTISTA
                        debe tener presente que TU SERENATA se reserva el derecho de admisión
                        para ARTISTAS que desean obtener este servicio, y que a su vez deberán
                        cumplir con lo siguiente: <br /> <br />
                        1) El ARTISTA debe tener una calificación de
                        estrellas no inferior a 4.6 <br /> <br />
                        2) No puede haber incumplido más de un servicio en el último año. <br /> <br />
                        3) Tener saldo disponible en su cuenta para adquirir los créditos del servicio Premium. <br /> <br />
                        Este servicio Premium para ARTISTAS no tiene vencimiento, y su valor
                        es el de $8.000 COP por crédito. Este valor es diferente al del crédito
                        publicitario normal de TU SERENATA, ya que le permitirá al ARTISTA estar
                        incluido en la lista de AGRUPACIONES RECOMENDADAS POR EL SISTEMA. Esta opción
                        se encuentra presente, cuando el USUARIO: 1) Necesita una agrupación
                        urgentemente, debido a que algún ARTISTA incumplió con el servicio programado
                        dentro del sistema. 2) Cuando el USUARIO desea contratar una agrupación musical
                        en la opción RESERVA PARA YA!, El sistema le recomienda al USUARIO elegir está
                        opción, debido a que es más confiable, gracias al trabajo realizado en la admisión
                        del servicio Premium, y que le permite a TU SERENATA recomendar con mayor
                        seguridad al ARTISTA. <br /> <br />
                        El ARTISTA, podrá comprar los créditos Premium por unidad, y/o por paquetes de
                        hasta cien (100) serenatas; manejando las siguientes tarifas y porcentajes de
                        descuento: <br /> <br />
                        1) 1 serenata equivale a $8.000 COP. <br />
                        2) El paquete de 5 serenatas tiene un valor de $40.000 COP, sin descuento. <br />
                        3) El paquete de 10 serenatas viene con un descuento del 5%, y tiene un valor de $76.000 COP. <br />
                        4) El paquete de 20 serenatas viene con un descuento del 10%, y tiene un valor de $144.000 COP. <br />
                        5) El paquete de 50 serenatas viene con un descuento del 15%, y tiene un valor de $340.000 COP. <br />
                        6) El paquete de 100 serenatas viene con un descuento del 20%, y tiene un valor de $640.000 COP.

                    </p>


                    <h3>DEVOLUCIONES</h3>
                    <p> Solo aplica para ARTISTAS. En caso de devoluciones, el ARTISTA deberá contactar directamente a
                        servicio al cliente de TU SERENATA, que se encuentra desde la app o en la página web
                        www.tu-serenata.com <br /> <br />
                        El ARTISTA, en el ejercicio de solicitar devolución de su dinero a TU SERENATA, deberá tener en cuenta
                        lo siguiente: <br /> <br />
                        1) Únicamente se hace devolución del dinero, por compras realizadas sobre el servicio que
                        ofrece TU SERENATA. Esto hace referencia a los créditos publicitarios normales, y créditos del servicio
                        Premium. Del mismo modo también, por el saldo que se encuentra en la cuenta del ARTISTA y que está visible
                        en su perfil desde la app. <br /> <br />
                        2) TU SERENATA, No realizara devolución del dinero sobre créditos publicitarios
                        normales, o del servicio Premium, que ya se encuentren extintos o actualmente en uso. <br /> <br />
                        3) TU SERENATA, NO se hace responsable por la cancelación del evento por parte del USUARIO, en caso tal que el ARTISTA
                        haya realizado el desplazamiento hasta el sitio del evento, y esto le acarre sobrecostos en viáticos, nomina etc.
                        TU SERENATA, al ver que el evento no se llevó a cabo por incumplimiento o cancelación del evento por parte del USUARIO,
                        realizara la devolución del valor del crédito publicitario normal o del crédito Premium, directamente al saldo
                        de su cuenta artística que se encuentra en la aplicación. <br /> <br />
                        4) TU SERENATA No se hace responsable por la devolución del dinero referente al costo del servicio musical, que presto el ARTISTA, y que este sea motivo de inconformidad por parte del
                        USUARIO quien recibió el servicio. Esto debido a que TU SERENATA NO tiene acuerdos con ningún ARTISTA, simplemente
                        es una herramienta digital en la cual un USUARIO puede comunicarse y/o contratar con cualquier ARTISTA que está
                        ofreciendo sus servicios musicales a través de TU SERENATA. <br /> <br />
                        5) TU SERENATA, No hace devolución en dinero por los puntos que ella misma otorga, ni tampoco por los paquetes de visualizaciones que el ARTISTA adquiere para aparecer
                        en la sección llamada DESTACADOS. <br /> <br />
                        6) En caso de cancelación del servicio por parte del USUARIO, en el servicio Premium,
                        TU SERENATA le devolverá nuevamente el crédito a los mismos y este podrá usarse normalmente. <br /> <br />
                        7) El ARTISTA deberá redactar un mensaje desde la opción CONTACTANOS, que se encuentra en la app o en la página web www.tu-serenata.com ,
                        especificando claramente el motivo por el cual solicita la devolución de su dinero. De esta manera TU SERENATA se
                        pondrá en contacto vía e-mail, para dar respuesta sobre su solicitud y de ser posible realizar el pago de la devolución
                        del dinero en el menor tiempo posible.

                    </p>

                    <h3>SISTEMA DE RECOMPENSAS Y SANCIONES</h3>
                    <p>TU SERENATA ha creado e implementado un sistema de recompensas, en el cual
                        los USUARIOS y los ARTISTAS, son premiados con una cantidad de puntos por
                        utilizar el servicio que ofrece la app. En el caso del ARTISTA, el sistema le
                        otorga puntos cada vez que cumple exitosamente un servicio musical, y cuyo
                        contrato se realizó por medio de TU SERENATA. Además de esto, también obsequia
                        puntos por actualizar su imagen de perfil, subir imágenes y videos musicales
                        de su agrupación. En el caso del USUARIO, el sistema le otorga puntos cada vez
                        que contrata una agrupación musical, y el evento se lleva a cabo exitosamente.
                        Además de esto, también por calificar y agregar un comentario a la agrupación
                        musical. <br />
                        Dichos puntos tienen caducidad límite de un (1) año desde su última obtención,
                        y podrán ser acumulados e intercambiados por los obsequios que ofrece la app en
                        la página web www.tu-serenata.com .
                    </p>


                </div>


                {/*
                <div className="sistema_puntos">
                    <h3>SISTEMA DE PUNTOS:</h3>
                    <p>El ARTISTA obtiene 10 puntos por serenata y 5puntos por obtener una calificación de 5 estrellas. Los USUARIOS obtienen 10 puntos por haber contratado una serenata por medio del sistema.</p>

                    <h3>¿Cómo funciona?</h3>
                    <p>1. El USUARIO ingresa a la aplicación. <br /> <br />
                        2. Busca el grupo de interés. <br /> <br />
                        3. Luego reserva con el ARTISTA que desee. <br /> <br />
                        4. El ARTISTA cumple con el servicio. <br /> <br />
                        5. Tanto USUARIO como ARTISTA confirman que se culminó la serenata. Ambos califican el servicio y la atención del otro, y, automáticamente obtienen los puntos que otorga el sistema. El USUARIO recibe diez (10) puntos por concluir el evento, calificar y agregar un comentario al ARTISTA. El ARTISTA, recibe diez (10) puntos por concluir el evento y diez (5) puntos más si obtiene una calificación de cinco (5) estrellas, por parte del USUARIO. <br /> <br />
                        6. El ARTISTA también podrá obtener más puntos, actualizando periódicamente (cada veinte 20 días) sus imágenes de multimedia (1 punto por cada imagen de la galería) y foto de perfil (1 punto); que le permitan al USUARIO mejorar la experiencia dentro de la app.
                    </p>

                    <h3>RECOMPENSAS</h3>
                    <p>Cuando los USUARIOS y los ARTISTAS acumulen puntos, estos podrán ser redimidos por varios premios; y cabe aclarar que los premios para ambas partes serán diferentes. <br />
                        Premios para ARTISTAS <br />
                        Estar en la sección de DESTACADOS, que tiene TU SERENATA.
                    </p>

                    <h3>¿Cómo reclamarlos?</h3>
                    <p> 1. Ingresar a la aplicación. <br />
                        2. Ingresar en la sección Puntos y Créditos.<br />
                        3. Ingresar en la pestaña Puntos.<br />
                        4. Cambia tus puntos por pauta publicitaria en DESTACADOS de TU SERENATA.
                    </p>

                    <h3>Premios para USUARIOS</h3>
                    <p>Los premios son de diferentes variedades, y se entregaran a dichos USUARIOS. Para efectuar su entrega, el USUARIO deberá entregar toda la información necesaria a TU SERENATA para que pueda entregarle dicho premio. Estos obsequios estarán señalados en la página web www.tu-serenata.com </p>


                    <h3>¿Cómo reclamarlos?</h3>

                    <p> 1. Ingresar a la cuenta. <br />
                        2. Ingresar en la sección Puntos. <br />
                        3. Dar Clic en el botón (Clic aquí para ver los premios).<br />
                        4. Escoge el premio y agrégalo al carrito de compras.<br />
                        5. Espera la respuesta de TU SERENATA para que sepas cuándo y dónde reclamar tu premio.
                    </p>

                    <h3>FALTAS Y SANCIONES</h3>
                    <p>TU SERENATA también cuenta con un sistema de sanciones que multe a las personas que no utilicen adecuadamente la herramienta. Dichas sanciones funcionan de la siguiente manera: <br />
                        NOTA: Cada evento se debe cancelar mínimo con una hora de anticipación.
                    </p>

                    <h3>Faltas para ARTISTAS</h3>
                    <p> 1. El ARTISTA incumple un evento contratado. <br />
                        2. El ARTISTA, NO llega dentro del tiempo establecido del evento, o dentro de la extensión de tiempo que haya acordado con el USUARIO.<br />
                        3. El ARTISTA cancela un evento fuera del tiempo establecido.<br />
                        4. Publicidad Falsa.
                    </p>

                    <h3>Sanciones para ARTISTAS</h3>
                    <p> 1. Si el ARTISTA acumula tres (3) faltas, tendrá una sanción y será inhabilitado de la plataforma durante un (1) mes. <br />
                        2. Si el ARTISTA acumula tres (3) sanciones, será inhabilitado de la plataforma durante nueve (9) meses. <br />
                        3. La Publicidad falsa y/o la reincidencia de las sanciones después de la tercera, causará la expulsión inmediata e indefinida del ARTISTA en la aplicación.
                    </p>


                    <h3>Faltas para USUARIOS</h3>
                    <p>1. El USUARIO NO responde o NO recibe la serenata sin haber cancelado con anticipación. <br />
                        2. El USUARIO cancela una serenata fuera del tiempo establecido.</p>

                    <h3>Sanciones para USUARIOS</h3>
                    <p> 1. Si el USUARIO acumula tres (3) faltas, tendrá una sanción y será inhabilitado de la plataforma durante un (1) mes. <br />
                        2. Si el USUARIO acumula tres (3) sanciones, será inhabilitado de la plataforma durante nueve (9) meses. <br />
                        3. La reincidencia de las sanciones después de la tercera, causará la expulsión inmediata del USUARIO en la plataforma.
                    </p>

                    <h3>EXTENSIONES DE TIEMPO DEL EVENTO</h3>
                    <p>TU SERENATA, pensando en los acontecimientos previos que se pueden presentar al evento, creo una función dentro de la app denominada (EXTENSION DE TIEMPO), la cual le permite al USUARIO extender el tiempo de llegada si</p>


                    <h3>PROPÓSITO DEL USO DE LOS SERVICIOS</h3>
                    <p>El USUARIO y el ARTISTA se comprometen bajo su responsabilidad, a sólo utilizar la herramienta digital TU SERENATA, con propósitos y fines permitidos por la ley. El USUARIO y el ARTISTA, NO usarán la app para causar incomodidad  o inconveniencia a otros. Está prohibido usar la app para impedir, obstaculizar, burlar, bloquear o impedir el correcto funcionamiento del sistema. Está prohibida la copia y/o distribución del software que hace parte de TU SERENATA, ni en su totalidad ni en alguna de sus partes, sin permiso por escrito de los dueños de la propiedad intelectual de TU SERENATA, inclusive, si el fin de dicha copia es una actividad sin lucro económico. El software de TU SERENATA es para uso personal e intransferible. Está prohibida la venta a terceros del, software, parte del software, o del acceso a la herramienta digital y los servicios que esta brinda. El USUARIO y el ARTISTA deben proveer al sistema las pruebas de identificación que le sean solicitadas por el app.</p>

                    <h3> DERECHOS DEL USUARIO Y DEL ARTISTA</h3>
                    <p>La descarga de la app TU SERENATA a su aparato electrónico, confiere tanto al USUARIO como al ARTISTA el derecho a su uso personal, de manera no exclusiva y no transferible. Ninguno de los dos mencionados tiene derecho a licenciar, vender, revender, re-licenciar, asignar, distribuir, anunciar, explotar o sacar algún provecho comercial del software de la app, de la marca, o de las funciones de TU SERENATA fuera del que la app les ofrece. Ninguno de los dos mencionados tiene el derecho a modificar o a hacer trabajos basados en el software, partes del software o la marca de TU SERENATA, tampoco tienen derecho a hacer links, ni hacer espejos de los servicios, ni hacer “reverse-ingeneering” (desarmar la aplicación en sus componentes para entender su funcionamiento, así sea con fines académicos y/o no lucrativos), ni de prestar a través de su perfil, el servicio de TU SERENATA a terceros apropiándose de los derechos de autor.</p>
                    <p>El USUARIO y el ARTISTA, podrán utilizar el historial del contrato entre ambas partes mencionadas, y que se realizó a través de TU SERENATA por medio del chat, como muestra, argumento o testimonio, del mismo; en caso tal, de que exista algún tipo de inconformidad referente al servicio musical y necesiten acudir a las autoridades competentes, para la resolución del conflicto. TU SERENATA, No se hace responsable por la confiabilidad tanto del USUARIO como del ARTISTA, si el contrato o cualquier acuerdo al que lleguen ambas partes, se realiza por medio de una llamada telefónica. Por el contrario, TU SERENATA, les recomienda tanto al USUARIO, como al ARTISTA, que cualquier acuerdo al que lleguen con la contraparte siempre quede registrado por escrito, por medio del chat.</p>

                    <h3>PROPIEDAD INTELECTUAL</h3>
                    <p>La propiedad intelectual del desarrollo de la aplicación y del servicio que presta TU SERENATA es propiedad exclusiva de C.S.R. WORLD DIGITAL COMPANY SAS. Esto incluye tanto la totalidad del software, como sus partes, así como la marca, el nombre, el logo, y/o cualquiera de sus componentes que permiten el correcto funcionamiento del sistema.</p>

                    <h3>TERCEROS</h3>
                    <p>TU SERENATA es una herramienta digital, que facilita la comunicación entre USUARIO y ARTISTA para contratar un evento musical. Usar la app no añade ni exonera al USUARIO o al ARTISTA de cumplir los deberes y derechos en relación contractual de lo sujeto en este documento. TU SERENATA, NO tiene ni deberes ni derechos dentro de la relación contractual que se establece entre el USUARIO y el ARTISTA.</p>

                </div>
                */}

            </div >

        </div>
    )
}

export default PoliticasCondiciones;

