import withRouter from "services/withRouter";
import * as React from "react";


const ScrollToTop = ({ location, children }) => {
    React.useEffect(() => {
        if (location && location.pathname) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return children;
};

export default withRouter(ScrollToTop);
