import employee_angry from 'images/employee_angry.svg';
import { NavLink } from 'react-router-dom';
import * as React from 'react';


const NoMatch = () => {

    React.useEffect(() => {
        document.title = "Página no encontrada";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_error">
            <p>
                <span>Error 404</span>
                <b>¡lo sentimos!</b>
                La página que buscas no se ha encontrado en este servidor,
                puede deberse a que la dirección se ha escrito de forma incorrecta o que ha sido cancelada.
                Revisa la dirección URL e intenta de nuevo o ve a nuestro inicio <NavLink to="/"> aqui.</NavLink>
            </p>
            <img src={employee_angry} alt="imagen de error" />
        </div>
    );
}

export default NoMatch;