import * as  React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import imagen_perfil from "images/img_fondo_4.jpg";
import { useChat, useMedia } from "hooks";
import funciones from "services/funciones";
import * as Iconos from "images/iconos";
import { Alert } from "shared/Alert";


export function Chat(params) {
    const { data, removeChat } = params;
    const { maxi, setMaxi } = useChat();
    const [visible, setVisible] = React.useState(true);
    const [magen, setMargen] = React.useState(0);


    const ChatVacio = () => {
        return (
            <div className="chat_vacio">
                <img src={imagen_perfil} alt="" />
                <h2>Nombre del artista</h2>
                <h4>Género</h4>
                <h5>última conexión 23:50</h5>
                <p></p>
            </div>
        );
    }

    const Conversacion = () => {
        return (
            <>
                <div className="conversacion">

                    <div>
                        <h4 className="_date">16 Octubre</h4>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. error Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:30</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem </p>
                            <h5>20:32</h5>
                        </div>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:37</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam, error.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa ipsam possimus eius cum porro dicta voluptatum itaque adipisci tempora atque quae, quam fugiat eligendi commodi, nisi vitae a repellat repellendus?</p>
                            <h5>21:30</h5>
                        </div>
                    </div>

                    <div>
                        <h4 className="_date">17 Octubre</h4>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. error Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:30</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem </p>
                            <h5>20:32</h5>
                        </div>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:37</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam, error.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa ipsam possimus eius cum porro dicta voluptatum itaque adipisci tempora atque quae, quam fugiat eligendi commodi, nisi vitae a repellat repellendus?</p>
                            <h5>21:30</h5>
                        </div>
                    </div>

                    <div>
                        <h4 className="_date">18 Octubre</h4>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. error Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:30</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem </p>
                            <h5>20:32</h5>
                        </div>

                        <div className="respuesta_artista">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                            <h5>20:37</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam, error.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa ipsam possimus eius cum porro dicta voluptatum itaque adipisci tempora atque quae, quam fugiat eligendi commodi, nisi vitae a repellat repellendus?</p>
                            <h5>21:30</h5>
                        </div>

                        <div className="respuesta_usuario">
                            <p>Otra prueba</p>
                            <h5>21:31</h5>
                        </div>
                    </div>

                </div>
            </>
        );
    }

    React.useEffect(() => {
        maxi.map((v, i) => {
            if (v.id === data.id_artista) {
                setMargen(36 * i);
                setVisible(v.value);
            }
        });
    }, [maxi]);

    return (
        <div className={visible ? "chat" : "chat _minimizado"}
            style={{ left: visible ? "auto" : magen }}>

            <div className="chat_artista">

                <div className="barra_chat">
                    <Link to="/artista" state={{ data: data }}><img src={data.img_perfil || imagen_perfil} alt={data.nombre_artista} />{data.nombre_artista}</Link>

                    {visible ?
                        <button onClick={() => setMaxi(data.id_artista, false)}><Iconos.Minus /></button>
                        :
                        <button onClick={() => setMaxi(data.id_artista, true)}><Iconos.WindowRestore /></button>
                    }

                    <button onClick={() => removeChat(data.id_artista)}><Iconos.CloseCircle /></button>
                </div>

                {visible && <>
                    <Conversacion />

                    <div className="escribir_chat">
                        <button><Iconos.EmoticonHappy /></button>
                        <input type="text" placeholder="Aa" />
                        <button><Iconos.Send /></button>
                    </div>
                </>}

            </div>

        </div>
    )
}

export function ListaChat() {
    const navigate = useNavigate();
    const location = useLocation();
    const { data_user } = useMedia();
    const { chaters, addChat } = useChat();
    const [openList, setOpenList] = React.useState(false);

    const handleAddChat = (e, data) => {
        e.preventDefault();

        if (data_user.auth) {
            addChat(data);
        } else {
            Alert("Es necesario que inicies sesión antes de continuar con esta acción.", "warning", () => navigate("/sign-in", { replace: true, state: { from: location } }));
        }
    }

    const handleClose = (e) => {
        e.preventDefault();

        if (typeof android !== "undefined") {
            document.body.style.overflow = "auto";
        }
        setOpenList(false);
    }

    const handleOpen = (e) => {
        e.preventDefault();

        if (typeof android !== "undefined") {
            document.body.style.overflow = "hidden";
        }
        setOpenList(true);
    }

    const MiniCard = ({ data }) => {

        return (
            <div className="sugerenicia_uno" onClick={e => handleAddChat(e, data)}>
                <img src={data.img_perfil || imagen_perfil} alt={data.nombre_artista} />

                <div className="info_sugerencia">
                    <h2>{data.nombre_artista}</h2>
                    <h4>{data.genero}</h4>

                    <h6>{funciones.getTimeAgo(data.genero)}</h6>
                </div>
            </div>
        )
    }

    const Vacia = () => {
        return (
            <div className="sugerencia">
                <p className="nota">No hay sugerencias de chat, usa la opcion de buscar y encuentra a tu artista favorito.</p>
            </div>
        );
    }

    const Sugerencias = () => {
        return (
            <div className="sugerencia">
                {chaters.length > 0 ?
                    chaters.map((item, index) => {
                        return <MiniCard key={index} data={item} />;
                    })
                    :
                    <Vacia />
                }
            </div>
        )
    }

    return (
        <>
            {openList ?
                <div className="lista_chat">
                    <div className="barra">
                        <h4 className="titulo_sugerencias">Mis chats <Iconos.CloseCircle onClick={handleClose} /></h4>

                        <div className="input_buscar">
                            <input type="text" placeholder="Buscar" />
                            <Iconos.Magnify />
                        </div>

                        <h5>Recientes</h5>
                    </div>

                    <Sugerencias />
                </div>
                :
                <div className="_boton_chat" onClick={handleOpen}>
                    <Iconos.Chats />
                </div>
            }
        </>
    )
}