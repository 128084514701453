import * as React from "react";
import logo from "images/logo_blanco.svg";
import * as Iconos from "images/iconos";


const RecuperarClave = () => {
    return (
        <div className="cont_principal recuperar_clave">

            <div className="cont_form">
                <img src={logo} alt="logo" />

                <h3>Recupera Tu Contraseña</h3>

                <form>
                    <p>La contraseña se enviara al correo electrónico que indiques en el siguiente campo
                        Por lo tanto asegúrate de que sea un correo al que tienes acceso.  </p>

                    <div className="inputs">
                        <Iconos.Email />
                        <input type="email" placeholder="Ingresa tu correo" />
                    </div>

                    <button type="button" className="boton boton-primary">Enviar</button>
                </form>
            </div>
        </div>
    )
}

export default RecuperarClave;