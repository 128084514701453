import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from 'images/logo_blanco.svg';
import { useMedia } from 'hooks';
import * as Iconos from 'images/iconos';
import * as React from 'react';

/* eslint eqeqeq: 0 */

export const Header = () => {
    const { data_user } = useMedia();
    const headerMenu = React.useRef(null);
    const media_movil = window.matchMedia('(max-width: 512px)').matches;


    const toggleMenu = (e) => {
        let _header = headerMenu.current;

        if (_header.className !== "responsive") {
            _header.className = "responsive";
        } else {
            _header.className = "";
        }
    }

    const handleClick = (e) => {

        if (media_movil) {
            toggleMenu(e);
        }
    }

    return (
        <header role="banner" ref={headerMenu}>
            <Link to="/"><img className="logo" src={logo} alt="Logo Tu serenata" /></Link>

            <div className="icon" onClick={toggleMenu}>
                <div className="icon-burger1"></div>
                <div className="icon-burger2"></div>
                <div className="icon-burger3"></div>
            </div>

            <nav>
                {/* <Link to="/"><samp>Inicio</samp><Iconos.SvgSombrero /></Link> */}
                {data_user.auth ?
                    <>
                        {data_user.nivel == 4 && <Link onClick={handleClick} to="/mi-perfil"><samp>Mi perfil</samp><Iconos.SvgSombrero /></Link>}
                    </>
                    :
                    <Link onClick={handleClick} to="/sign-in"><samp>Iniciar sesión</samp><Iconos.SvgSombrero /></Link>
                }
                <Link onClick={handleClick} to="/trabaja-con-nosotros"><samp>Trabaja con nosotros</samp><Iconos.SvgSombrero /></Link>
                <Link onClick={handleClick} to="/notificaciones"><samp>Notificaciones</samp><Iconos.SvgSombrero /></Link>
                <Link onClick={handleClick} to="/otra-opcion"><samp>Otra opción</samp><Iconos.SvgSombrero /></Link>
                <Link onClick={handleClick} to="/otra-opcion"><samp>Otra opción</samp><Iconos.SvgSombrero /></Link>
                <Iconos.Magnify className="_lupa" />
            </nav>
        </header>
    )
}

export const HeaderMovil = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data_user } = useMedia();

    React.useEffect(() => {
        console.log('location', location.pathname);

        let _divs = document.getElementsByClassName("cont_principal");

        for (let i = 0; i < _divs.length; i++) {
            if (_divs[i].classList.contains("_movil")) {
                // _divs[i].classList.remove("_movil");
            } else {
                _divs[i].classList.add("_movil");
            }
        }
    }, [location]);

    return (
        <>
            <header role="banner" className="_movil">
                <button onClick={() => navigate(-1)}><Iconos.ArrowLeft /></button>
                <button><Iconos.DotsVertical /></button>
            </header>

            <div className="toolbar">
                <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/notificaciones">
                    <Iconos.Campana /><samp>Notificaciones</samp>
                </NavLink>

                {data_user.auth ?
                    <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/mi-perfil">
                        <Iconos.Account /><samp>Mi perfil</samp>
                    </NavLink>
                    :
                    <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/sign-in">
                        <Iconos.Account /><samp>Iniciar sesión</samp>
                    </NavLink>
                }

                <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/">
                    <Iconos.Inicio /><samp>Inicio</samp>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/contactanos">
                    <Iconos.SvgSombrero /><samp>Trabaja con nosotros</samp>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? 'is_active' : ''} to="/terminos-y-condiciones">
                    <Iconos.SvgSombrero /><samp>Politicas de uso</samp>
                </NavLink>

            </div>
        </>
    )
}