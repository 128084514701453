import * as React from "react";
import profile_acording from "images/profile_acording.jpg";
import profile_default from "images/profile_default.jpg";
import withRouter from "services/withRouter";
import { Link } from "react-router-dom";
import * as Iconos from "images/iconos";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */

const MasArtistas = (router) => {
    const { navigate, location, params } = router;
    const [showLoading, setShowLoading] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [artistas, setArtistas] = React.useState([]);
    const { genero } = params;

    const verificaFile = async (_url) => {
        let file_name = _url.split("/").pop();

        if (file_name == null || file_name == "null") {
            _url = profile_acording;
            file_name = "profile_acording.jpg";
        } else {
            let response = await fetch(_url);

            let data = await response.blob();

            if (data.size < 200) {
                console.log("\n\n\nverificación ", file_name);
                console.log("url ", _url);
                console.log("response ", response);
                console.log("data ", data);
                console.log("Datos muy bajos", data.size, response.url);

                _url = profile_default;
                file_name = "default.jpg";
            }
        }

        // let file = new File([data], file_name, { type: "image/" + file_name.split(".").pop(), lastModified: Date.now()});
        // file = Object.assign(file, { preview: _url, url: _url, path: file_name });

        return _url;
    }

    const getArtistasLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "all_genero",
                genero: genero,
                offset: artistas.length
            }
        });

        if (_data.response == 1) {
            _data.data.map(async (item, index) => {
                item.img_perfil = await verificaFile(process.env.REACT_APP_IMAGES + "profile_images/" + item.img_perfil);

                setArtistas(a => [...a, item]);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    const getArtistas = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "all_genero",
                genero: genero,
                offset: 0
            }
        });

        if (_data.response == 1) {
            _data.data.map(async (item, index) => {
                item.img_perfil = await verificaFile(process.env.REACT_APP_IMAGES + "profile_images/" + item.img_perfil);

                setArtistas(a => [...a, item]);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    const goArtist = (e, item) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/artista", { state: { data: item } });
    }

    React.useEffect(() => {
        getArtistas();
    }, [genero]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_principal more_artist">
            <h2>{genero}</h2>

            <div className="cont_artistas">
                {artistas.map((item, index) => <div key={index} className="marco_artista">
                    <Link to="artista" className="marco" onClick={e => goArtist(e, item)}>
                        <img src={item.img_perfil} alt={item.nombre_artista} />
                        <div className="info">
                            <h4>{item.nombre_artista.length > 50 ? item.nombre_artista.substring(0, 50) + "..." : item.nombre_artista}</h4>
                            {item.descripcion ?
                                <p>{item.descripcion.length > 160 ? item.descripcion.substring(0, 160) + "..." : item.descripcion}</p>
                                :
                                <p>...</p>
                            }
                        </div>
                    </Link>
                </div>)}
            </div>

            {showLoading ?
                <button className="btn_lazy_load"><Iconos.Loading /></button>
                :
                <>
                    {showLazy ?
                        <button className="btn_lazy_load" onClick={() => getArtistasLazy()}>Cargar mas</button>
                        :
                        <h4 className="h4_lazy_load">{artistas.length} artistas del genero "{genero.toLowerCase()}"</h4>
                    }
                </>
            }
        </div>
    )
}

export default withRouter(MasArtistas);