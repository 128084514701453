import * as React from 'react';
import Mapa from 'images/mapa.png';
import Mariachis from 'images/iconos/mariachis.svg';
import Andina from 'images/iconos/andina.svg';
import Jazz from 'images/iconos/jazz.svg';
import Llanera from 'images/iconos/llanera.svg';
import Orquesta from 'images/iconos/orquesta.svg';
import Papayera from 'images/iconos/papayera.svg';
import Rock from 'images/iconos/rock.svg';
import Solista from 'images/iconos/solista.svg';
import Tunas from 'images/iconos/tunas.svg';
import Vallenato from 'images/iconos/vallenato.svg';
import Footer from '../shared/footer';


const PorqueNosotros = () => {
    return (
        <div className="cont_principal porque_nosotros">
            <div className="cont_parrafo">

                <h3>¿POR QUÉ NUESTRO SERVICIO ES NECESARIO?</h3>
                <p>Muchas personas a diario contratan todo tipo de serenatas,
                    pero casi siempre es difícil el contacto con el grupo
                    musical o saber cual es la calidad del servicio de su trabajo.
                    Lo que hacemos en Tu Serenata es que de una manera más organizada, practica
                    y eficaz, facilitamos la comunicación entre ambas partes,
                    acortamos las distancias con que usualmente una persona
                    debe ir al sitio en el que se ubican los artistas, mostramos la
                    calidad de trabajo de este mismo y premiamos a cada usuario cuando
                    utiliza nuestro servicio.
                </p>


                <h3>GÉNEROS MUSICALES</h3>
                <div className="generos">

                    <div className="clase">
                        <p><img src={Mariachis} alt="mariachis" /> MARIACHIS</p>
                        <p><img src={Solista} alt="solista" />BOLEROS Y TRIOS</p>
                        <p><img src={Solista} alt="solista" />NORTEÑAS</p>
                        <p><img src={Tunas} alt="tunas" />TUNAS</p>
                        <p><img src={Rock} alt="rock" />BANDAS DE ROCK</p>
                    </div>

                    <div className="clase">
                        <p><img src={Llanera} alt="llaner" />LLANERAS</p>
                        <p><img src={Papayera} alt="papayera" />PAPAYERA</p>
                        <p><img src={Solista} alt="solista" />CARRANGUERA</p>
                        <p><img src={Solista} alt="solista" />SOLISTAS</p>
                        <p><img src={Jazz} alt="jazz" />JAZZ</p>
                    </div>

                    <div className="clase">
                        <p><img src={Solista} alt="solista" />POPULAR</p>
                        <p><img src={Vallenato} alt="vallenato" />PARRANDA VALLENATA</p>
                        <p><img src={Andina} alt="andina" />MUSICA ANDINA</p>
                        <p><img src={Orquesta} alt="orquesta" />ORQUESTAS TROPICALES</p>
                    </div>

                </div>



                <h3>¿DONDE PUEDO CONTRATAR UN SERVICIO?</h3>

                <div className="ciudades">
                    <ul>
                        <li>Armenia</li>
                        <li>Barrancabermeja</li>
                        <li>Barranquilla</li>
                        <li>Bogotá</li>
                        <li>Bucaramanga</li>
                        <li>Cali</li>
                        <li>Cartagena</li>
                        <li>Cartago</li>
                        <li>Cúcuta</li>
                        <li>Florencia</li>
                        <li>Ibagué</li>
                        <li>Manizales</li>
                        <li>Medellín</li>
                        <li>Montería</li>
                    </ul>

                    <img src={Mapa} alt="mapa" />

                    <ul>

                        <li>Neiva</li>
                        <li>Palmira</li>
                        <li>Pasto</li>
                        <li>Pereira</li>
                        <li>Popayán</li>
                        <li>Quibdó</li>
                        <li>Riohacha</li>
                        <li>San Andrés & Provide</li>
                        <li>Santa Marta</li>
                        <li>Tuluá</li>
                        <li>Tunja</li>
                        <li>Valledupar</li>
                        <li>Villavicencio</li>

                    </ul>

                </div>


                <h3>Sistema de puntos</h3>
                <p>TU SERENATA ha creado e implementado un sistema de
                    recompensas, en el cual los USUARIOS y los ARTISTAS,
                    son premiados con una cantidad de puntos por utilizar
                    el servicio que ofrece la app. Dichos puntos podrán
                    ser acumulados y posteriormente intercambiados por
                    los obsequios que ofrece la app en la página web
                    www.tu-serenata.co estos puntos tendrán vigencia de
                    (1) un año. Si el USUARIO no acumula más puntos en
                    dicho tiempo la totalidad de sus puntos se vencerá.
                </p>


                <h5>¿Cómo funciona?</h5>

                <ul>
                    <li>El Usuario ingresa a la aplicación.</li>
                    <li>Busca el género musical y la ciudad</li>
                    <li>Luego reserva con el artista que desee.</li>
                    <li>El artista cumple con el servicio</li>
                    <li>Tanto usuario como artista confirman que se culminó
                        la serenata, ambos califican el servicio y la atención
                        del otro, y automáticamente obtienen los puntos que
                        otorga la app.
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default PorqueNosotros;

