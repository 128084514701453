import { useLocation } from "react-router-dom";
import { Chat } from "shared/chat";
import * as React from "react";
import log from "services/log";

const ContextChat = React.createContext();
const ContextMedia = React.createContext();

export function MediaProvider({ children }) {
    /** @type {DataUser} */
    const [data_user, configInfo] = React.useState(new SharedPreferences().getString("user") ?? { auth: false, permisos: {}, token: "not apply" });
    const [media_movil, setMediaMovil] = React.useState(window.innerWidth > 576 ? false : true);
    const location = useLocation();

    /**
     * Configura el titulo de la paguína actual y
     * guarda el estado para cuando caduque la sesión 
     * redirija a esta ultima pagina
     * @param {String} title titulo de la pagina 
     */
    const setTitulo = (_titulo) => {
        document.body.style.overflow = "auto";
        document.title = _titulo;
        log.title(_titulo);

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            android.setLastPage(window.location.href);
        }
    }

    const setDataUser = (_info) => {
        let _shared_preferenses = new SharedPreferences();
        configInfo(inf => {
            let _json;
            if (_info.clear) {
                _shared_preferenses.remove("user");
                _json = { auth: false, token: "not apply" };
            } else {
                _json = { ...inf, ..._info }
                _shared_preferenses.putString("user", _json);
            }
            return _json;
        });
    }

    React.useEffect(() => {
        let _params = {};
        location.search.replace("?", "").split("&").forEach(p => {
            let _p = p.split("=");
            _params[_p[0]] = _p[1];
        });

        if (_params?.ref === "twa") {
            window.android = {
                ..._params,
                setLastPage: (url) => {
                    log.d("setLastPage", url);
                },
                setSwipeRefresh: (val) => {
                    log.d("setSwipeRefresh", val);
                },
                getVersion: () => {
                    log.d("getVersion", _params.version);
                    return _params.version;
                },
                verWebAuxiliar: (url) => {
                    log.d("verWebAuxiliar", url);
                },
                scannerQR: () => {
                    log.d("scannerQR");
                },
                closeWebAuxiliar: () => {
                    log.d("closeWebAuxiliar");
                },
                getToken: () => {
                    log.d("getToken");
                },
                saveDataLogin: (login) => {
                    log.d("saveDataLogin", login);
                },
                eliminaArchivo: (file) => {
                    log.d("eliminaArchivo", file);
                }
            }
            log.i("params", _params);
        }

        // ?ref=twa&source=android&version=2.0.0
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps 

    React.useEffect(() => {
        let mediaQuery = window.matchMedia('(max-width: 576px)');

        let handleChange = (e) => {
            setMediaMovil(e.matches);
        }

        mediaQuery.addListener(handleChange);

        return () => mediaQuery.removeListener(handleChange);
    }, []);

    return <ContextMedia.Provider
        value={{ media_movil, data_user, setDataUser, setTitulo }}>
        {children}
    </ContextMedia.Provider>
}

export function ChatProvider({ children }) {
    const [chats, setChats] = React.useState([]);
    const [chaters, setChaters] = React.useState([]);
    const [maxi, set_m] = React.useState([]);


    const setMaxi = (_id, _val) => {

        set_m(m => {
            let _lement = m.filter(c => c.id === _id)[0];

            if (_lement) {
                let _new = m.filter(c => c.id !== _id);

                _lement.value = _val;
                _new.push(_lement);

                return _new;
            } else {
                return [...m, { id: _id, value: _val }];
            }
        });
    }

    const addChat = (_data) => {

        set_m(m => {
            let _lement = m.filter(c => c.id === _data.id_artista)[0];

            if (_lement) {
                let _new = m.filter(c => c.id !== _data.id_artista);

                _lement.value = true;
                _new.push(_lement);

                return _new;
            } else {
                return [...m, { id: _data.id_artista, value: true }];
            }
        });

        setChats(i => {
            let _lement = i.filter(c => c.key === _data.id_artista)[0];

            if (_lement) {
                // i.splice(0, 0, i.splice(i.length - 1, 1)[0]);
                let _new = i.filter(c => c.key !== _data.id_artista);

                _new.push(_lement);

                return _new;
            } else {
                return [...i, <Chat key={_data.id_artista} data={_data} removeChat={removeChat} />];
            }
        });
    }

    const removeChat = (_key) => {
        _key = parseInt(_key);
        set_m(m => m.filter(i => parseInt(i.id) !== _key));
        setChats(c => c.filter(i => parseInt(i.key) !== _key));
    }

    // React.useEffect(() => {
    //     setChaters([
    //         { id_artista: '15', nombre_artista: 'Artista uno', genero: "Norteñas" },
    //         { id_artista: '19', nombre_artista: 'Nombre del artista', genero: "Boleros o Tríos" },
    //         { id_artista: '21', nombre_artista: 'Otro artista', genero: "Bandas de Rock" },
    //         { id_artista: '22', nombre_artista: 'Artista dos', genero: "Música andina" },
    //         { id_artista: '23', nombre_artista: 'Otro nombre de artista', genero: "Tunas" },
    //     ]);
    // }, []);

    return <ContextChat.Provider
        value={{ chaters, setChaters, chats, addChat, maxi, setMaxi }}>
        {children}
    </ContextChat.Provider>
}

export { ContextChat, ContextMedia };

export class SharedPreferences {

    constructor() {
        this.data = JSON.parse(localStorage.getItem("shared_preferences") ?? "{}");
    }

    /**
     * Obtiene un valor de shared preferences
     * 
     * @param {string} param El nombre del valor a obtener
     * @returns {string} El valor de shared preferences 
     */
    getString(param) {
        return this.data[param];
    }

    /**
     * Obtiene un valor numérico de shared preferences
     * 
     * @param {string} param El nombre del valor a obtener
     * @returns {number} El valor numérico del valor a obtener
     */
    getIng(param) {
        return parseInt(this.data[param]);
    }

    /**
     * Agrega un valor a shared preferences
     * 
     * @param {string} param El nombre del valor a agregar
     * @param {string} value El valor a agregar 
     */
    putString(param, value) {
        this.data[param] = value;
        localStorage.setItem("shared_preferences", JSON.stringify(this.data));
    }

    /**
     * Agrega un valor numérico a shared preferences
     * 
     * @param {string} param El nombre del valor a agregar
     * @param {number} value El valor a agregar 
     */
    putInt(param, value) {
        this.data[param] = value;
        localStorage.setItem("shared_preferences", JSON.stringify(this.data));
    }

    /**
     * Elimina un valor de shared preferences
     * 
     * @param {string} param El nombre del valor a eliminar
     */
    remove(param) {
        delete this.data[param];
        localStorage.setItem("shared_preferences", JSON.stringify(this.data));
    }
}