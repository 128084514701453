import * as React from "react";
import profile_acording from "images/profile_acording.jpg";
import profile_default from "images/profile_default.jpg";
import * as Iconos from "images/iconos";
import { Link } from "react-router-dom";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */

const Carousel = ({ genero, navigate, location }) => {
    const [artistas, setArtistas] = React.useState([]);
    const fila = React.useRef(null);


    const verificaFile = async (_url) => {
        let file_name = _url.split("/").pop();

        if (file_name == null || file_name == "null") {
            _url = profile_acording;
            file_name = "profile_acording.jpg";
        } else {
            let response = await fetch(_url);

            let data = await response.blob();

            if (data.size < 200) {
                console.log("\n\n\nverificación ", file_name);
                console.log("url ", _url);
                console.log("response ", response);
                console.log("data ", data);
                console.log("Datos muy bajos", data.size, response.url);

                _url = profile_default;
                file_name = "default.jpg";
            }
        }

        // let file = new File([data], file_name, { type: "image/" + file_name.split(".").pop(), lastModified: Date.now()});
        // file = Object.assign(file, { preview: _url, url: _url, path: file_name });

        return _url;
    }

    const getArtistas = async () => {
        let _data = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "por_genero",
                genero
            }
        });

        if (_data.response == 1) {
            _data.data.map(async (item, index) => {
                item.img_perfil = await verificaFile(process.env.REACT_APP_IMAGES + "profile_images/" + item.img_perfil);

                setArtistas(a => [...a, item]);
            });

        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    const goLeft = (e) => {
        e.preventDefault();
        let _carousel = fila.current;
        let _tira_artista = _carousel.parentNode;
        let _marco_artista = _carousel.firstElementChild.offsetWidth;

        // _arr.splice(0, 0, _arr.splice(_arr.length - 1, 1)[0]);
        // _arr.map(item => {
        //     _new.push(item);
        // });
        // setArtistas(_new);

        _tira_artista.scrollLeft -= _marco_artista + 24;
    }

    const goRight = (e) => {
        e.preventDefault();
        let _carousel = fila.current;
        let _tira_artista = _carousel.parentNode;
        let _marco_artista = _carousel.firstElementChild.offsetWidth;

        // _arr.splice(_arr.length - 1, 0, _arr.splice(0, 1)[0]);
        // _arr.map(item => {
        //     _new.push(item);
        // });
        // setArtistas(_new);

        _tira_artista.scrollLeft += _marco_artista + 24;
    }

    React.useEffect(() => {
        getArtistas();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="categoria">
            <div className="titulo_categoria">
                <h3>{genero}</h3>
                <Link to={{ pathname: "/mas-artistas/" + genero, state: { genero: genero } }}>ver mas</Link>
            </div>

            <div className="tira_artista">
                <button onClick={goLeft} className="flecha-izquierda"><Iconos.AngleLeft /></button>

                <div className="cont_carousel">
                    <div className="carousel" ref={fila}>
                        {artistas.map((item, index) => <div key={index} className="marco_artista">
                            <Link to="/artista" state={{ data: item }} className="marco">
                                <img src={item.img_perfil} alt={item.nombre_artista} />
                                <div className="info">
                                    <h4>{item.nombre_artista.length > 50 ? item.nombre_artista.substring(0, 50) + "..." : item.nombre_artista}</h4>
                                    {item.descripcion ?
                                        <p>{item.descripcion.length > 160 ? item.descripcion.substring(0, 160) + "..." : item.descripcion}</p>
                                        :
                                        <p>...</p>
                                    }
                                </div>
                            </Link>
                        </div>)}
                    </div>
                </div>

                <button onClick={goRight} className="flecha-derecha"><Iconos.AngleRight /></button>
            </div>
        </div>
    )
}

export default Carousel;