import * as React from "react";
import * as Iconos from "images/iconos";
import * as ReactDOMClient from 'react-dom/client';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";


const ImgComponent = (params) => {
    const imgRef = React.useRef(null);
    console.log("params", params);

    const onDismiss = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        params.modal.unmount();
    }

    const onUpdate = React.useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty("transform", value);
        }

        document.body.style.overflow = "hidden";
    }, []);

    return (
        <div className="modal_img">
            <div className="modal_bkg-blur"></div>
            <QuickPinchZoom onUpdate={onUpdate}>
                <img src={params.preview} alt={params.name} ref={imgRef} />
            </QuickPinchZoom>
            <Iconos.CloseCircle onClick={onDismiss} />
        </div>
    );
}

const VisorImg = (params) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));
    params.modal = _modal;

    _modal.render(<ImgComponent {...params} />);
}

export default VisorImg;