import * as React from "react";
import Footer from "shared/footer";
import * as Iconos from "images/iconos";
import { useMedia } from "hooks";
import { Link } from "react-router-dom";


const Registro = () => {
    const { data_user, setDataUser, setTitulo } = useMedia();

    React.useEffect(() => {
        setTitulo("Trabaja con Nosotros");
    }, []);

    return (
        <div className="cont_principal working_with_us">

            <div className="cont_form">

                <h3>Trabaja con Nosotros</h3>

                <div className="form">


                    <label>Nombre de Artista</label>
                    <div className="inputs">

                        <Iconos.Account />
                        <input type="text" />

                    </div>

                    <div className="documento">

                        <div className="tipo">
                            <label>Tipo de documento</label>
                            <select className="inputs">
                                <option>Seleccionar...</option>
                                <option>Cédula de Ciudadanía</option>
                                <option>Cédula de Extrangería</option>
                                <option>NIT</option>


                            </select>
                        </div>

                        <div className="numero_doc">
                            <label>Número de documento</label>
                            <div className="inputs">

                                <Iconos.CardAccountDetails />
                                <input type="text" />

                            </div>
                        </div>

                    </div>


                    <div className="contacto">

                        <div className="email">
                            <label>Correo</label>
                            <div className="inputs">

                                <Iconos.Email />
                                <input type="email" />

                            </div>
                        </div>


                        <div className="telefono">
                            <label>Télefono de contacto</label>
                            <div className="inputs">
                                <Iconos.Phone />

                                <input type="number" />


                            </div>
                        </div>

                    </div>


                    <div className="localizar">

                        <div className="ciudad">

                            <label>Ciudad</label>
                            <select className="inputs">

                                <Iconos.Account />
                                <input type="text" />
                                <option>Seleccionar...</option>
                                <option>Armenia</option>
                                <option>Barrancabermeja</option>
                                <option>Barranquilla</option>
                                <option>Bogotá</option>
                                <option>Bucaramanga</option>
                                <option>Cali</option>
                                <option>Cartagena</option>
                                <option>Cartago</option>
                                <option>Cúcuta</option>
                                <option>Florencia</option>
                                <option>Ibagué</option>
                                <option>Manizales</option>
                                <option>Medellín</option>
                                <option>Montería</option>
                                <option>Neiva</option>
                                <option>Palmira</option>
                                <option>Pasto</option>
                                <option>Pereira</option>
                                <option>Popayán</option>
                                <option>Quibdó</option>
                                <option>Riohacha</option>
                                <option>San Andrés & Provide</option>
                                <option>Santa Marta</option>
                                <option>Tuluá</option>
                                <option>Tunja</option>
                                <option>Tunja</option>
                                <option>Valledupar</option>
                                <option>Villavicencio</option>

                            </select>

                        </div>


                        <div className="genero">
                            <label>Género</label>
                            <select className="inputs">
                                <option>Seleccionar...</option>
                                <option>Bandas de Rock</option>
                                <option>Boleros y Trios</option>
                                <option>Carranguera</option>
                                <option>Jazz</option>
                                <option>Llaneras</option>
                                <option>Mariachis</option>
                                <option>Musica Andina</option>
                                <option>Norteñas</option>
                                <option>Orquestas Tropicales</option>
                                <option>Papayera</option>
                                <option>Parranda Vallenata</option>
                                <option>Popular</option>
                                <option>Solistas</option>
                                <option>Solistas</option>
                                <option>Tunas</option>


                            </select>
                        </div>

                    </div>


                    <label>Mensaje</label>
                    <div className="inputs _mensaje">

                        <textarea cols="30" rows="6" placeholder=" Escribenos una breve descripcion de tu agrupación..."></textarea>
                    </div>

                    <p>Al pulsar registrar estas aceptando los</p>
                    <Link to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</Link>

                    <button className="boton boton-primary">ENVIAR</button>

                </div>

            </div>

        </div >
    );
}

export default Registro;