import * as React from 'react';
import logo from 'images/logo_blanco.svg';
import { Link } from 'react-router-dom';
import * as Iconos from 'images/iconos';


const Registro = () => {
    return (
        <div className="cont_principal registro">

            <div className="cont_form">
                <img src={logo} alt="logo" />

                <h3>Registro de Usuario</h3>

                <form>
                    <label>Correo</label>
                    <div className="inputs">
                        <Iconos.Email />
                        <input type="email" />
                    </div>

                    <label>Nombre</label>
                    <div className="inputs">
                        <Iconos.Account />
                        <input type="text" />
                    </div>

                    <label>Fecha de nacimiento</label>
                    <div className="inputs">
                        <Iconos.Calendar />
                        <input type="date" />
                    </div>

                    <label>Contraseña</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type="password" />
                        <Iconos.EyeOff />
                    </div>

                    <label>Confirmar Contraseña</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type="password" />
                    </div>

                    <p>Al pulsar registrar estas aceptando los</p>
                    <Link to="/terminos-y-condiciones">Términos & condiciones</Link>

                    <button type="button" className="boton boton-primary">REGISTRAR</button>

                </form>
            </div>
        </div>
    )
}

export default Registro;