import * as React from "react";
import logo from "images/logo_blanco.svg";
import { useMedia } from "hooks";


const Contactanos = () => {
    const { data_user, setDataUser, setTitulo } = useMedia();

    React.useEffect(() => {
        setTitulo("Contactanos");
    }, []);

    return (
        <div className="cont_principal contactanos" style={{paddingTop: "64px"}}>

            <div className="cont_form">
                <img src={logo} alt="logo" />

                <h3>Contactanos</h3>

                <form>
                    <label>Nombre</label>
                    <div className="inputs">
                        <input type="text" placeholder="Nombre" />
                    </div>

                    <label>Correo</label>
                    <div className="inputs">
                        <input type="mail" placeholder="Correo" />
                    </div>

                    <label>Asunto</label>
                    <div className="inputs">
                        <input type="text" placeholder="Asunto" />
                    </div>

                    <label>Mensaje</label>
                    <div className="inputs">
                        <textarea name="mensaje" rows="6" placeholder="Mensaje"></textarea>
                    </div>

                    <button type="button" className="boton boton-primary">Enviar</button>
                </form>
            </div>
        </div>
    )
}

export default Contactanos;