import * as React from 'react';
import Footer from '../shared/footer';

const SobreNosotros = () => {
    return (
        <div className="cont_principal sobre_nosotros">

            <div className="cont_parrafo">

                <h3>¿Quienes somos?</h3>
                <p>Tu Serenata es una Aplicación móvil que mejora el sistema de
                    reserva de una serenata, acortando el tiempo de búsqueda, y
                    ofreciendo variedad dentro de una plataforma que está
                    disponible las 24 horas del día.
                </p>

                <div className="vi_mi">

                    <div className="mision">
                        <h3>Misión</h3>
                        <p>Innovar el sistema de contratación existente de serenatas,
                            creando así, una herramienta digital, que facilite al publico
                            la búsqueda y reserva de este tipo de servicios.
                        </p>

                    </div>

                    <div className="vision">
                        <h3>Visión</h3>
                        <p>Convertirnos en la herramienta de contratación de serenatas
                            mas importante de Latinoamérica con proyección mundial.
                        </p>
                    </div>

                </div>

                <h3>Nuestros Valores</h3>
                <div className="valores">

                    <div className="list_valores">
                        <h5>COMPROMISO</h5>
                        <p>Con la funcionalidad de Tu Serenata.</p>
                    </div>

                    <div className="list_valores">
                        <h5>HONESTIDAD</h5>
                        <p>Con la publicidad que sube cada agrupación a Tu Serenata.</p>
                    </div>

                    <div className="list_valores">
                        <h5>RESPETO</h5>
                        <p>Con el trato hacia los artistas y usuarios finales.</p>
                    </div>

                    <div className="list_valores">
                        <h5>SOLIDARIDAD</h5>
                        <p>Para con las personas que carecen de habilidad del
                            manejo de la tecnología digital.</p>

                    </div>


                </div>


            </div>
        </div>
    )
}



export default SobreNosotros;


