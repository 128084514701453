import * as React from "react";
import { ContextChat, ContextMedia } from "../Contexts";

export function useMedia() {
    /** @type {{ media_movil: boolean, data_user: DataUser, setDataUser: React.Dispatch<React.SetStateAction<DataUser>>, setTitulo: React.Dispatch<React.SetStateAction<string}} */
    const { media_movil, data_user, setDataUser, setTitulo } = React.useContext(ContextMedia);

    return { media_movil, data_user, setDataUser, setTitulo };
}

export function useChat() {
    const { chaters, setChaters, chats, addChat, maxi, setMaxi } = React.useContext(ContextChat);

    return { chaters, setChaters, chats, addChat, maxi, setMaxi };
}