import CryptoJS from "crypto-js";
import { SharedPreferences } from "./Contexts";
import log from "./log";

/* eslint eqeqeq: 0 */
/* eslint import/no-anonymous-default-export: 0 */

const WEB_API = process.env.REACT_APP_WEB_API;

class API {

    /**
     * Genera la firma de autenticación
     * necesaria para autorizar la consulta en el servidor
     * @return {string}: Un string con el digest 
     */
    getAuth(type = "normal") {
        let _token = "mZhndVeRrhLoNc6qaQNy0BldbBYtIzQa69+tD9/kdMN/aCjjMZgv6yhfwfPXrVN/elk8qOYA17/0BsiAFtjoBVUwzewccWKH9ekdGE+yteIOtPr9gvYDJKZL1Adkiia4";
        let _guest = "aW52aXRhZG8=";
        let _data = new SharedPreferences().getString("user");
        let _auth = _data == null ? window.btoa(0) : _data.auth;
        let current_time = new Date().getTime() / 1000;
        let limit_time = _data == null ? 0 : _data.time;
        let _time = ((limit_time - current_time) / 60).toFixed(0);

        if (_time > 60) {
            _time = (_time / 60).toFixed(0);
            if (_time > 24) {
                log.i("Tiempo restante: " + (_time / 24).toFixed(1) + " días");
            } else {
                log.i("Tiempo restante: " + _time + " horas");
            }
        } else {
            log.i("tiempo restante: " + _time + " minutos");
        }

        if (type === "invitado") {
            return `Digest auth="${_guest}", time="${current_time.toFixed(0)}", alive="${typeof android !== "undefined" ? "android" : "1"}", nonce="${Math.floor(Math.random() * 100)}", token="${_token}", validate="${CryptoJS.MD5(_guest + ":" + current_time.toFixed(0) + ":" + _token).toString()}"`;
        } else if (typeof android !== "undefined") {
            return `Digest auth="${_auth}", time="${_data.time}", alive="android", nonce=${_data.nonce}, token="${_data.token}", validate="${_data.validate}"`;
        } else {

            if ((limit_time - current_time) <= 0) {
                new SharedPreferences().remove('user');
                log.e("Se borró la sesión con '" + (limit_time - current_time).toFixed(0) + "' segundos");
                return `Digest auth="${_guest}", time="${current_time.toFixed(0)}", nonce="${Math.floor(Math.random() * 100)}", token="${_token}", validate="${CryptoJS.MD5(_guest + ":" + current_time.toFixed(0) + ":" + _token).toString()}"`;
            } else {
                return `Digest auth="${_auth}", time="${_data.time}", nonce=${_data.nonce}, token="${_data.token}", validate="${_data.validate}"`;
            }
        }
    }

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        // console.log(`\n Response status ${response.status} \n`)

        switch (response.status) {
            case 200:
            case 210:
                //log.d('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                log.e('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
            case 501:
            case 502:
                log.e('Error', response.status);
                // throw new Error('500 - Ocurrio un error en la sintaxis!');
                break;
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo más a fondo`);
        }
    }

    /**
     * Verifica el correo y la clave en la base de datos 
     * @param {String} email: el correo del usuario en texto plano
     * @param {String} pass: La clave del usuario en texto plano
     * @return {Promise<JSON>}: Json con los datos del usuario
     */
    async signIn(email, pass) {
        let clave = CryptoJS.SHA256(pass);
        let _data;
        let token = "";

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            // token = await android.getToken();

            console.log("token: " + token);
        }

        await fetch(`${WEB_API}sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                email: email.trim(),
                pass: clave.toString(),
                token: token
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                _data = data;
            })
            .catch((error) => {
                _data = { response: -1, msg: error.toString() };
            });

        return _data;
    }

    /**
     * Hace una consulta al servidor 
     * @param {JSON} params: json con { `url`: string, `data`: json }
     * @return {Promise<JSON>}: `json` con los datos solicitados
     */
    async fetchJson(params) {
        let _data;

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                _data = data;
            })
            .catch((error) => {
                console.log('Ocurrio un error:', error);
                _data = { response: -1, msg: error.toString() };
            });

        if (_data.response == 1 && _data?.auth?.token) {
            _data = await this.updateSession(_data);
        }

        return _data;
    }

    async updateSession(data) {

        return new Promise((resolve, reject) => {
            try {
                let _shared_preferenses = new SharedPreferences();
                let _user = _shared_preferenses.getString("user");

                if (_user == null) {
                    resolve({ ...data, response: -2, msg: "¡No se encuentran los datos de la sesión! \nDebes iniciar sesión de nuevo." });
                } else {
                    _shared_preferenses.putString("user", {
                        ..._user,
                        time: data?.auth?.time,
                        nonce: data?.auth?.nonce,
                        token: data?.auth?.token,
                        version: data?.auth?.version,
                        validate: data?.auth?.validate
                    });
                    resolve(data);
                }
            } catch (error) {
                reject({ ...data, response: -1, msg: "Ocurrio un error al actualizar la sesión: " + error.toString() });
            }
        });
    }
}

export default new API();