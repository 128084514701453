import * as React from "react";


export function SvgHome({ color = "rgb(255,255,255)" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 20000 20000">
            <path fill={color} d="M 18543,8809 L 11190,1456 C 10959,1224 10672,1070 10359,1005 L 9675,1005 C 9362,1070 9074,1224 8844,1456 L 1495,8805 C 1492,8808 1489,8810 1487,8813 843,9460 845,10509 1490,11155 1785,11450 2175,11621 2591,11639 2608,11641 2625,11641 2643,11641 L 2936,11641 2936,17053 C 2936,18124 3807,18995 4878,18995 L 7755,18995 C 8046,18995 8283,18758 8283,18467 L 8283,14224 C 8283,13736 8680,13338 9169,13338 L 10865,13338 C 11353,13338 11751,13736 11751,14224 L 11751,18467 C 11751,18758 11987,18995 12279,18995 L 15156,18995 C 16227,18995 17098,18124 17098,17053 L 17098,11641 17370,11641 C 17813,11641 18230,11469 18543,11155 18782,10917 18933,10623 18995,10314 L 18995,9650 C 18933,9342 18782,9048 18544,8810 L 18543,8809 Z M 17745,10630 L 16902,10630 C 16595,10630 16346,10878 16346,11181 L 16346,17383 C 16346,17893 15927,18308 15413,18308 14588,18308 13356,18244 12531,18244 L 12531,14365 C 12531,13247 12048,12588 10920,12588 L 9133,12588 C 8005,12588 7436,13341 7436,14459 L 7436,18338 C 6612,18338 5413,18308 4588,18308 4073,18308 3655,17893 3655,17383 L 3655,11181 C 3655,10878 3406,10630 3099,10630 L 2272,10630 C 2262,10630 2254,10629 2245,10629 2079,10626 1924,10561 1807,10445 1560,10200 1560,9800 1807,9555 L 1808,9555 1808,9554 C 4389,6995 6971,4436 9552,1878 9672,1759 9831,1693 10000,1693 10170,1693 10330,1759 10450,1878 13030,4436 15611,6994 18191,9553 18192,9554 18193,9555 18195,9556 18441,9802 18441,10201 18193,10445 18073,10564 17914,10630 17745,10630 Z" />
        </svg>);
}

export function SvgContactanos({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 511.945 511.945">
            <path fill={color} d="m470.896 192.153-32.924-32.938v-65.27c0-22.056-17.944-40-40-40h-65.2l-50.973-51.003c-1.875-1.875-4.418-2.93-7.07-2.931l-37.253-.011c-.001 0-.002 0-.003 0-2.651 0-5.194 1.053-7.069 2.927l-51.05 51.019h-65.382c-22.056 0-40 17.944-40 40v65.374l-32.902 32.826c-9.735 9.73-15.097 22.669-15.097 36.434v231.855c0 13.875 5.524 26.476 14.476 35.747.193.231.394.458.611.676.216.216.442.415.672.607 9.271 8.954 21.874 14.48 35.751 14.48h133.49c5.523 0 10-4.478 10-10s-4.477-10-10-10h-133.491c-5.047 0-9.816-1.199-14.05-3.318l117.869-117.869 38.374 38.351c9.728 9.723 22.658 15.076 36.411 15.076h.016c13.759-.004 26.692-5.366 36.418-15.098l38.214-38.238 117.778 117.778c-4.233 2.118-9.003 3.318-14.05 3.318h-133.489c-5.523 0-10 4.478-10 10s4.477 10 10 10h133.491c13.875 0 26.478-5.525 35.749-14.478.231-.193.458-.393.674-.609.218-.218.418-.445.612-.677 8.951-9.271 14.475-21.872 14.475-35.746v-231.87c-.001-13.752-5.355-26.683-15.078-36.412zm-32.923-4.648 18.777 18.786c1.734 1.735 3.233 3.641 4.498 5.673l-23.275 23.29zm-196.361-167.504 28.969.01 33.914 33.935h-96.849zm-186.41 186.298 18.771-18.728v47.65l-23.269-23.254c1.265-2.031 2.765-3.936 4.498-5.668zm-5.912 268.186c-2.118-4.233-3.317-9.002-3.317-14.049v-224.923l121.182 121.107zm229.085-79.535c-5.95 5.953-13.861 9.232-22.278 9.235-.003 0-.006 0-.009 0-8.413 0-16.323-3.275-22.274-9.223l-139.841-139.754v-161.263c0-11.028 8.972-20 20-20h284c11.028 0 20 8.972 20 20v68.134c-.095.795-.095 1.597 0 2.392v90.794zm187.598 65.486c0 5.047-1.199 9.816-3.317 14.049l-117.782-117.782 121.099-121.173z" />
            <path fill={color} d="m255.973 491.942c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07c1.86 1.87 4.44 2.93 7.07 2.93s5.21-1.06 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07c-1.86-1.859-4.44-2.93-7.07-2.93z" />
            <path fill={color} d="m255.973 115.883c-52.062 0-94.417 42.354-94.417 94.416 0 52.774 42.935 95.709 95.709 95.709h60.779c5.523 0 10-4.478 10-10s-4.477-10-10-10h-60.779c-41.746 0-75.709-33.963-75.709-75.709 0-41.033 33.383-74.416 74.417-74.416s74.417 33.383 74.417 74.416v20.493c0 7.876-6.408 14.283-14.284 14.283s-14.284-6.407-14.284-14.283v-23.147c0-27.379-22.274-49.653-49.653-49.653-28.79 0-52.211 23.422-52.211 52.212 0 28.789 23.422 52.211 52.211 52.211h15.908c5.523 0 10-4.478 10-10s-4.477-10-10-10h-15.908c-17.761 0-32.211-14.45-32.211-32.212 0-17.761 14.45-32.211 32.211-32.211 16.351 0 29.653 13.303 29.653 29.653v23.147c0 18.904 15.379 34.283 34.284 34.283s34.284-15.379 34.284-34.283v-20.493c-.001-52.062-42.356-94.416-94.417-94.416z" />
        </svg>);
}

export function SvgEmail({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 612 612">
            <path fill={color} d="M306.768,346.814h0.131c4.615,0,9.176-1.339,12.866-3.777l1.001-0.643c0.218-0.142,0.446-0.271,0.675-0.424l11.658-9.645 l278.259-229.624c-0.576-0.795-1.557-1.339-2.602-1.339H3.233c-0.751,0-1.448,0.272-2.003,0.729l291.125,239.954 C296.024,345.083,301.259,346.814,306.768,346.814z M0,133.899v340.37l208.55-168.471L0,133.899z M403.668,306.941L612,474.356 V135.031L403.668,306.941z M337.431,361.585c-8.305,6.814-19.168,10.57-30.576,10.57c-11.451,0-22.304-3.734-30.587-10.516 l-47.765-39.394L0,506.806v0.587c0,1.753,1.502,3.244,3.276,3.244h605.491c1.741,0,3.232-1.491,3.232-3.255v-0.544L383.693,323.4 L337.431,361.585z" />
        </svg>);
}

export function SvgWhatsapp({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 512 512">
            <path fill={color} d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456 C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504 c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792 c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184 c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392 c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352 c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024 c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568 c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z" />
        </svg>);
}

export function SvgFacebook({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 96.124 96.123">
            <path fill={color} d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803	c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654 c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246 c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
        </svg>);
}

export function Facebook({ color = "currentColor", className = "itemIcon" }) {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path fill={color} d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
        </svg>
    );
}

export function SvgInstagram({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 512 512">
            <path fill={color} d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160 C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48 h192c61.76,0,112,50.24,112,112V352z" />
            <path fill={color} d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336 c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z" />
            <circle fill={color} cx="393.6" cy="118.4" r="17.056" />
        </svg>);
}

export function SvgTwitter({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 512 512">
            <path fill={color} d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016 c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992 c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056 c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152 c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792 c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44 C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568 C480.224,136.96,497.728,118.496,512,97.248z" />
        </svg>);
}

export function SvgYoutube({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 511.626 511.627">
            <path fill={color} d="M511.339,212.987c-0.186-10.277-1-23.271-2.423-38.97c-1.431-15.708-3.478-29.746-6.14-42.115		c-3.046-13.893-9.661-25.6-19.842-35.117c-10.181-9.519-22.031-15.037-35.549-16.562c-42.258-4.755-106.115-7.135-191.573-7.135		c-85.459,0-149.317,2.38-191.572,7.135c-13.516,1.524-25.319,7.043-35.404,16.562c-10.089,9.514-16.656,21.221-19.702,35.117		c-2.852,12.373-4.996,26.41-6.423,42.115c-1.425,15.699-2.235,28.688-2.424,38.97C0.094,223.265,0,237.539,0,255.813 c0,18.272,0.094,32.55,0.288,42.826c0.189,10.284,0.999,23.271,2.424,38.969c1.427,15.707,3.474,29.745,6.139,42.116 c3.046,13.897,9.659,25.602,19.842,35.115c10.185,9.517,22.036,15.036,35.548,16.56c42.255,4.76,106.109,7.139,191.572,7.139 c85.466,0,149.315-2.379,191.573-7.139c13.518-1.523,25.316-7.043,35.405-16.56c10.089-9.514,16.652-21.225,19.698-35.115	c2.854-12.371,4.996-26.409,6.427-42.116c1.423-15.697,2.231-28.691,2.423-38.969c0.191-10.276,0.287-24.554,0.287-42.826	C511.626,237.539,511.531,223.265,511.339,212.987z M356.883,271.231L210.706,362.59c-2.666,1.903-5.905,2.854-9.71,2.854		c-2.853,0-5.803-0.764-8.848-2.286c-6.28-3.422-9.419-8.754-9.419-15.985V164.454c0-7.229,3.14-12.561,9.419-15.986		c6.473-3.431,12.657-3.239,18.558,0.571l146.178,91.36c5.708,3.23,8.562,8.372,8.562,15.415		C365.446,262.854,362.591,267.998,356.883,271.231z" />
        </svg>);
}

export function SvgGooglePlay({ color = "currentColor" }) {
    return (
        <svg height="30" width="30" viewBox="0 0 512 512">
            <path fill={color} d="M111.758,8.189C91.726-2.883,68.206-2.627,48.302,8.349l233.28,215.2l78.368-78.368L111.758,8.189z" />
            <path fill={color} d="M24.974,30.365c-5.696,9.312-8.96,19.968-8.96,31.264v388.672c0,10.944,2.976,21.376,8.352,30.496l234.592-234.592 L24.974,30.365z" />
            <path fill={color} d="M463.854,202.525l-74.752-41.248l-84,83.968l102.944,94.944l55.84-30.816c20.096-11.136,32.128-31.104,32.128-53.44 C495.982,233.597,483.982,213.629,463.854,202.525z" />
            <path fill={color} d="M282.478,267.901L47.246,503.133c10.208,5.824,21.408,8.896,32.672,8.896c10.88,0,21.824-2.752,31.84-8.288 l266.784-147.232L282.478,267.901z" />
        </svg>);
}

export function GooglePlus({ color = "currentColor", className = "itemIcon" }) {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path fill={color} d="M23,11H21V9H19V11H17V13H19V15H21V13H23M8,11V13.4H12C11.8,14.4 10.8,16.4 8,16.4C5.6,16.4 3.7,14.4 3.7,12C3.7,9.6 5.6,7.6 8,7.6C9.4,7.6 10.3,8.2 10.8,8.7L12.7,6.9C11.5,5.7 9.9,5 8,5C4.1,5 1,8.1 1,12C1,15.9 4.1,19 8,19C12,19 14.7,16.2 14.7,12.2C14.7,11.7 14.7,11.4 14.6,11H8Z" />
        </svg>
    );
}

// -=-=-=-=-=-=-=-=-=- 24 -=-=-=-=-=-=-=-=-=- //

export function Minus({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M20,14H4V10H20" />
        </svg>
    );
}

export function Alert({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
        </svg>
    );
}

export function AlertRhombus({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M12 2C11.5 2 11 2.19 10.59 2.59L2.59 10.59C1.8 11.37 1.8 12.63 2.59 13.41L10.59 21.41C11.37 22.2 12.63 22.2 13.41 21.41L21.41 13.41C22.2 12.63 22.2 11.37 21.41 10.59L13.41 2.59C13 2.19 12.5 2 12 2M11 7H13V13H11V7M11 15H13V17H11V15Z" />
        </svg>
    );
}

export function Star({ color = "currentColor", className = "itemIcon" }) {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path fill={color} d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
        </svg>
    );
}

export function StarFull({ color = "currentColor", className = "itemIcon" }) {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path fill={color} d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
        </svg>
    );
}

export function StarMidle({ color = "currentColor", className = "itemIcon" }) {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path fill={color} d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
        </svg>
    );
}

export function Loading({ color = "currentColor" }) {
    return (
        <svg width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke={color} strokeWidth="12" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>
    );
}

export function Edit({ color = "currentColor" }) {
    return (
        <svg width="32" height="32" viewBox="0 0 24 24">
            <path fill={color} d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z" />
        </svg>
    );
}

export function Inicio({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill="none" stroke={color} strokeWidth="0.9" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
        </svg>
    );
}

export function AddAcount({ color = "currentColor" }) {
    return (
        <svg width="32" height="32" viewBox="0 0 24 24">
            <path fill={color} d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z" />
        </svg>
    );
}

export function Account({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill="none" stroke={color} strokeWidth="0.9" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    );
}

export function AccountConvert({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M12 0L11.34 .03L15.15 3.84L16.5 2.5C19.75 4.07 22.09 7.24 22.45 11H23.95C23.44 4.84 18.29 0 12 0M12 4C10.07 4 8.5 5.57 8.5 7.5C8.5 9.43 10.07 11 12 11C13.93 11 15.5 9.43 15.5 7.5C15.5 5.57 13.93 4 12 4M.05 13C.56 19.16 5.71 24 12 24L12.66 23.97L8.85 20.16L7.5 21.5C4.25 19.94 1.91 16.76 1.55 13H.05M12 13C8.13 13 5 14.57 5 16.5V18H19V16.5C19 14.57 15.87 13 12 13Z" />
        </svg>
    );
}

export function CardAccountDetails({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
        </svg>
    );
}

export function Delete({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M20.37,8.91L19.37,10.64L7.24,3.64L8.24,1.91L11.28,3.66L12.64,3.29L16.97,5.79L17.34,7.16L20.37,8.91M6,19V7H11.07L18,11V19A2,2 0 0,1 16,21H8A2,2 0 0,1 6,19Z" />
        </svg>
    );
}

export function AccountOff({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z" />
        </svg>
    );
}

export function LockOutline({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
    );
}

export function Eye({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
        </svg>
    );
}

export function EyeOff({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z" />
        </svg>
    );
}

export function Email({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
        </svg>
    );
}

export function ClockTimeFour({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z" />
        </svg>
    );
}

export function Calendar({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z" />
        </svg>
    );
}

export function Phone({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
        </svg>
    );
}

export function CheckBold({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            {/* <path fill={color} d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" /> */}
            <path fill={color} d="M 9.7003079,23.872702 C 7.9883957,19.378266 5.4976355,16.160429 0.27076482,12.805886 3.6508136,13.475423 7.7940683,15.537886 9.1026014,17.813047 12.068893,11.880912 16.367562,4.213711 23.729235,0.12729841 15.293771,9.5658759 13.304041,14.88554 9.7003079,23.872702 Z" />
        </svg>
    );
}

export function CheckboxMultipleMarked({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <path fill={color} d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
        </svg>
    );
}

export function CloseCircle({ color = "currentColor", onClick = () => { } }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" onClick={onClick}>
            <path fill={color} d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
        </svg>
    );
}

export function Magnify({ color = "currentColor", className = "svg_icon", onClick = () => { } }) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" onClick={onClick}>
            <path fill={color} d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
        </svg>
    );
}

export function EmoticonHappy({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" />
        </svg>
    );
}

export function WindowRestore({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M4,8H8V4H20V16H16V20H4V8M16,8V14H18V6H10V8H16M6,12V18H14V12H6Z" />
        </svg>
    );
}

export function DotsHorizontal({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
        </svg>
    );
}

export function DotsVertical({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
        </svg>
    );
}

export function ArrowLeft({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </svg>
    );
}

export function Trash({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" >
            <path fill={color} d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
        </svg>
    );
}

// -=-=-=-=-=-=-=-=-=- 200 - 300 -=-=-=-=-=-=-=-=-=- //

export function Chats({ color = "currentColor" }) {
    return (
        <svg width="32" height="32" viewBox="0 0 240 240">
            <path fill="none" stroke={color} strokeWidth="6" strokeLinejoin="round" strokeLinecap="round" d="M 151,92 C 153,57 125,29 92,26 57,21 27,42 16,66 10,76 -1,123 35,143 33,157 34,161 28,180 L 63,156 C 123,165 151,122 151,92 Z" />
            <path fill="none" stroke={color} strokeWidth="6" strokeLinejoin="round" strokeLinecap="round" d="M 111,173 C 114,187 141,215 179,202 179,202 194,218 211,223 206,204 207,202 205,186 258,146 220,77 168,79" />
        </svg>
    );
}

export function SvgSombrero({ color = "currentColor" }) {
    return (
        <svg height="24" width="40" viewBox="0 0 200 138">
            <g transform="translate(0,138) scale(0.1,-0.1)" fill={color}>
                <path d="M1032 1218 c-93 -35 -198 -169 -272 -349 -32 -76 -84 -248 -77 -255 2 -2 29 51 61 119 114 240 137 283 181 339 54 68 109 108 162 115 103 13 184 -131 202 -361 l6 -84 -130 -50 c-71 -28 -201 -80 -287 -116 -283 -117 -402 -136 -508 -82 -30 16 -72 42 -94 59 l-38 30 20 -39 c28 -53 89 -109 136 -124 60 -19 205 -7 304 25 46 14 174 64 283 110 218 92 443 169 532 183 68 11 154 -1 207 -28 20 -10 67 -51 103 -91 54 -58 65 -65 56 -41 -23 65 -75 138 -122 169 -91 61 -175 70 -314 34 -45 -11 -86 -21 -91 -21 -5 0 -12 33 -15 73 -13 147 -62 290 -120 348 -54 54 -109 65 -185 37z" />
                <path d="M225 677 c-72 -19 -113 -43 -135 -80 -38 -61 -20 -127 53 -194 61 -56 108 -79 206 -97 164 -31 323 -13 536 61 157 55 223 62 347 37 48 -10 88 -21 88 -24 0 -4 -35 -18 -77 -31 -69 -21 -74 -24 -44 -27 52 -5 169 18 194 38 21 18 21 20 7 47 -19 35 -75 51 -177 52 -40 1 -73 4 -73 9 1 9 209 106 288 134 148 51 328 -24 362 -153 23 -83 -52 -168 -168 -191 -65 -12 -295 -11 -394 2 -45 6 -131 24 -192 40 -114 30 -124 21 -26 -22 139 -61 218 -73 455 -73 168 1 236 15 297 62 54 42 78 83 78 132 0 136 -100 236 -267 264 -84 15 -140 -2 -391 -119 -230 -107 -397 -168 -520 -189 -155 -27 -347 -14 -432 30 -18 9 -52 36 -76 61 -37 36 -44 50 -44 82 0 67 67 108 192 118 68 5 202 -12 252 -32 13 -5 17 -4 13 3 -4 6 -37 23 -74 38 -80 33 -199 42 -278 22z" />
            </g>
        </svg>
    );
}

export function Campana({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 200 200">
            <g transform="matrix(0.09,0,0,-0.09,-10,190)" fill={color}>
                <path d="M915 1881 c-68 -32 -125 -111 -125 -175 0 -19 -12 -31 -53 -54 -76 -43 -167 -138 -209 -219 -64 -122 -70 -153 -78 -428 -7 -206 -12 -264 -29 -330 -32 -120 -64 -177 -134 -240 -62 -55 -62 -55 -47 -82 l16 -28 241 -3 242 -2 16 -49 c33 -99 143 -184 236 -183 105 2 210 79 243 180 l18 52 237 0 c218 0 239 2 254 18 27 30 20 46 -42 101 -68 59 -100 116 -132 236 -17 66 -22 125 -29 335 -6 221 -10 263 -28 313 -53 145 -146 265 -254 326 -42 24 -54 36 -56 57 -3 42 -37 107 -73 140 -60 56 -141 69 -214 35z m135 -96 c16 -9 36 -28 44 -43 l14 -27 -113 0 -113 0 14 27 c27 53 98 72 154 43z m121 -202 c116 -55 205 -155 251 -281 18 -49 22 -90 28 -307 5 -207 10 -265 28 -338 20 -80 66 -191 94 -224 8 -11 -98 -13 -572 -13 -320 0 -580 3 -578 8 2 4 17 36 35 72 61 127 75 207 83 490 6 233 9 260 30 315 43 112 114 200 205 254 81 49 134 62 241 57 77 -3 102 -8 155 -33z m-21 -1275 c0 -23 -45 -77 -81 -97 -69 -39 -138 -24 -198 44 -55 63 -51 65 124 65 113 0 155 -3 155 -12z" />
                <path d="M914 1536 c-148 -36 -258 -163 -287 -330 -10 -59 -9 -67 8 -86 21 -23 48 -26 63 -7 6 7 15 40 21 74 23 142 118 243 247 259 27 4 55 11 62 17 19 15 14 54 -10 71 -26 18 -35 19 -104 2z" />
            </g>
        </svg>
    );
}

// -=-=-=-=-=-=-=-=-=- 512 -=-=-=-=-=-=-=-=-=- //

export function Send({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 512 512">
            <path fill={color} d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
        </svg>
    );
}

export function Info({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 512 512">
            <path fill={color} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
        </svg>
    );
}

export function Information({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M13.5,4A1.5,1.5 0 0,0 12,5.5A1.5,1.5 0 0,0 13.5,7A1.5,1.5 0 0,0 15,5.5A1.5,1.5 0 0,0 13.5,4M13.14,8.77C11.95,8.87 8.7,11.46 8.7,11.46C8.5,11.61 8.56,11.6 8.72,11.88C8.88,12.15 8.86,12.17 9.05,12.04C9.25,11.91 9.58,11.7 10.13,11.36C12.25,10 10.47,13.14 9.56,18.43C9.2,21.05 11.56,19.7 12.17,19.3C12.77,18.91 14.38,17.8 14.54,17.69C14.76,17.54 14.6,17.42 14.43,17.17C14.31,17 14.19,17.12 14.19,17.12C13.54,17.55 12.35,18.45 12.19,17.88C12,17.31 13.22,13.4 13.89,10.71C14,10.07 14.3,8.67 13.14,8.77Z" />
        </svg>
    );
}

export function InformationCircle({ color = "currentColor" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M12.3 7.29C12.5 7.11 12.74 7 13 7C13.27 7 13.5 7.11 13.71 7.29C13.9 7.5 14 7.74 14 8C14 8.27 13.9 8.5 13.71 8.71C13.5 8.9 13.27 9 13 9C12.74 9 12.5 8.9 12.3 8.71C12.11 8.5 12 8.27 12 8C12 7.74 12.11 7.5 12.3 7.29M9.8 11.97C9.8 11.97 11.97 10.25 12.76 10.18C13.5 10.12 13.35 10.97 13.28 11.41L13.27 11.47C13.13 12 12.96 12.64 12.79 13.25C12.41 14.64 12.04 16 12.13 16.25C12.23 16.59 12.85 16.16 13.3 15.86C13.36 15.82 13.41 15.78 13.46 15.75C13.46 15.75 13.54 15.67 13.62 15.78C13.64 15.81 13.66 15.84 13.68 15.86C13.77 16 13.82 16.05 13.7 16.13L13.66 16.15C13.44 16.3 12.5 16.96 12.12 17.2C11.71 17.47 10.14 18.37 10.38 16.62C10.59 15.39 10.87 14.33 11.09 13.5C11.5 12 11.68 11.32 10.76 11.91C10.39 12.13 10.17 12.27 10.04 12.36C9.93 12.44 9.92 12.44 9.85 12.31L9.82 12.25L9.77 12.17C9.7 12.07 9.7 12.06 9.8 11.97M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12Z" />
        </svg>
    );
}

export function Comment({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 512 512">
            <path fill={color} d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" />
        </svg>
    );
}

export function Comments({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 640 512">
            <path fill={color} d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2s0 0 0 0s0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.2-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9c0 0 0 0 0 0s0 0 0 0l-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" />
        </svg>
    );
}

export function Handshake({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 640 512">
            <path fill={color} d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8 512 128l-.7 0-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48 0 224 28.2 0 91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16L0 352c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-224-80 0zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128l0 224c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-208c0-8.8-7.2-16-16-16l-80 0zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
        </svg>
    );
}

export function ListUl({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 512 512">
            <path fill={color} d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z" />
        </svg>
    );
}

export function AngleLeft({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 320 512">
            <path fill={color} d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
    );
}

export function AngleRight({ color = "currentColor" }) {
    return (
        <svg height="24" width="24" viewBox="0 0 320 512">
            <path fill={color} d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
        </svg>
    );
}
