import { Link } from 'react-router-dom';
import * as Iconos from 'images/iconos';
import * as React from 'react';


const Footer = () => {

    return (
        <footer className={typeof android !== "undefined" ? "_movil" : ""}>

            <div className="map_info">
                {/* <button onClick={cambia}> {traslate.lang == "es" ? "Español (CO)" : "English (US)"}</button> */}

                <div className="columna first_colum">
                    <h3>Ayuda y soporte</h3>

                    <Link to="/contactanos"><Iconos.SvgContactanos />Contactanos</Link>

                    <a href="mailto:soporte@tu-serenata.com"><Iconos.SvgEmail />soporte@tu-serenata.com</a>

                    <a href="https://wa.me/573002039123?text=Hola, necesito ayuda" target="_blank" rel="noreferrer"><Iconos.SvgWhatsapp />300 2039123</a>

                </div>

                <div className="columna">
                    <h3>Nuestras redes sociales</h3>

                    <a href="https://www.facebook.com/TuSerenatapp/?modal=admin_todo_tour"><Iconos.SvgFacebook />@TuSerenatapp</a>

                    <a href="https://www.instagram.com/tu_serenata/?hl=es-la"><Iconos.SvgInstagram />@tu_serenata</a>

                    <a href="https://twitter.com/Tu_Serenata?lang=es"><Iconos.SvgTwitter />@Tu_Serenata</a>

                    <a href="https://www.youtube.com/channel/UC3uq6xRx6rahogI2fCt_ZJg/"><Iconos.SvgYoutube />Tu Serenata</a>

                </div>

                <div className="columna">

                    <h3>Enlaces de interés</h3>

                    <Link to="terminos-y-condiciones">Política de privacidad</Link>

                    {/* <Link to="terminos-y-condiciones#terminos">Términos y condiciones</Link> */}

                    <Link to="porque-nosotros">Porque nosotros</Link>

                    <Link to="sobre-nosotros">Sobre nosotros</Link>

                    <h3>Descarga nuestra app</h3>
                    <a href="https://play.google.com/store/apps/details?id=com.sarguero.tuserenataco&hl=es"><Iconos.SvgGooglePlay />Versión Android</a>

                </div>
            </div>
            <hr />
            <div className="pie">
                <p>Copyright © 2018 - {new Date().getFullYear()} C.S.R. World Digital Company SAS Todos los derechos Reservados.</p>
                <h6>Desarrollado por <span>Sarguero</span><br /><i>{process.env.REACT_APP_BUILD_VERSION}</i></h6>
            </div>

        </footer>
    );
}

export default Footer;