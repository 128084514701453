import * as React from "react";
import logo from "images/logo_blanco.svg";
import * as Iconos from "images/iconos";
import { Link } from "react-router-dom";


const CambiarClave = () => {
    return (
        <div className="cont_principal recuperar_clave"  style={{paddingTop: "64px"}}>

            <div className="cont_form">
                <img src={logo} alt="logo" />

                <h3>Cambiar Contraseña</h3>

                <form>
                    <p>Una buena contraseña debería contener una mezcla de mayúsculas y minúsculas, números y símbolos.</p>

                    <label>Contraseña Actual</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type="password" />
                        <Iconos.EyeOff />
                    </div>

                    <Link to="/recuperar-clave">¿Olvidaste tu Contraseña?</Link>

                    <label>Nueva Contraseña</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type="password" />
                        <Iconos.EyeOff />
                    </div>

                    <label>Confirmar Contraseña</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type="password" />
                    </div>

                    <div className="botones">
                        <button className="boton boton-primary">CANCELAR</button>
                        <button className="boton boton-primary">CAMBIAR CONTRASEÑA</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CambiarClave;