import * as  React from "react";
import { numeroALetras } from "services/funciones";
import withRouter from "services/withRouter";
import { useMedia, useChat } from "hooks";
import InfoPopUp from "shared/InfoPopUp";
import * as Iconos from "images/iconos";
import { Link } from "react-router-dom";
import VisorImg from "shared/VisorImg";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const imagenes = [
    // { source: require('yourApp/image.png'), dimensions: { width: 150, height: 150 } },
    { source: { uri: 'https://i.imgur.com/XP2BE7q.jpg' } },
    { source: { uri: 'https://i.imgur.com/5nltiUd.jpg' } },
    { source: { uri: 'https://i.imgur.com/6vOahbP.jpg' } },
    { source: { uri: 'https://i.imgur.com/kj5VXtG.jpg' } },
    { source: { uri: "https://firebasestorage.googleapis.com/v0/b/tuserenata-202223.appspot.com/o/artista%2Fperfil%2FA_123.jpg?alt=media&token=730e484d-bb8f-4961-b108-255b9ab2e415" } },
    { source: { uri: "https://firebasestorage.googleapis.com/v0/b/tuserenata-202223.appspot.com/o/artista%2Fperfil%2FP_123.jpg?alt=media&token=a1da28d6-3c71-49b4-a5b8-097817df6b94" } },
    { source: { uri: "https://firebasestorage.googleapis.com/v0/b/tuserenata-202223.appspot.com/o/artista%2Fperfil%2FP_124.jpg?alt=media&token=328dcaaa-e4a9-4f09-b09f-2731ebdc49ac" } },
    { source: { uri: "https://hdwallpaperim.com/wp-content/uploads/2017/08/22/92147-Hirosaki_Kanade-Your_Diary-anime_girls-Kantoku.jpg" } },
    { source: { uri: "https://wallup.net/wp-content/uploads/2016/04/10/309995-anime-anime_girls-sword-katana-long_hair-original_characters-dress.jpg" } },
    // { source: { uri: "https://firebasestorage.googleapis.com/v0/b/tuserenata-202223.appspot.com/o/artista%2Fperfil%2FP_125.jpg?alt=media&token=74995d8d-5ae5-4ac7-97fd-9846d238ad60" } }
];

const Perfil = (router) => {
    const { navigate, location } = router;
    const { data_user, setTitulo } = useMedia();
    const [data, setData] = React.useState([]);
    const { setChaters, addChat } = useChat();
    const [showLoading, setShowLoading] = React.useState(false);
    const [comentarios, setComentarios] = React.useState([]);
    const [calificacion, setCalificacion] = React.useState(0);
    const [cTab, setCurrentTab] = React.useState(0);
    const [artistas, setArtistas] = React.useState([]);


    const verificaFile = async (_url) => {
        let file_name = _url.split("/").pop();

        if (file_name == null || file_name == "null") {
            _url = process.env.REACT_APP_IMAGES + "profile_images/PROFILE_162_IMG-20190426-WA0025.jpg";
            file_name = "PROFILE_162_IMG-20190426-WA0025.jpg";
        } else {
            let response = await fetch(_url, {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });

            let data = await response.blob();

            if (data.size < 1800) {
                console.log("Datos muy bajos", data.size, response.url);

                _url = process.env.REACT_APP_IMAGES + "profile_images/default.jpg";
                file_name = "default.jpg";
            }
        }

        // let file = new File([data], file_name, { type: "image/" + file_name.split(".").pop(), lastModified: Date.now()});
        // file = Object.assign(file, { preview: _url, url: _url, path: file_name });

        return _url;
    }

    const handleAddChat = (e) => {
        e.preventDefault();

        if (data_user.auth) {
            addChat(data);
            setChaters(c => {
                let _lement = c.filter(i => i.id_artista === data.id_artista)[0];

                if (_lement) {
                    return c;
                } else {
                    return [...c, data];
                }
            });
        } else {
            Alert("Es necesario que inicies sesión antes de continuar con esta acción.", "warning", () => navigate("/sign-in", { state: { from: location, data: data } }));
        }
    }

    const handleOpenContrat = (e) => {
        e.preventDefault();

        if (data_user.auth) {

        } else {
            Alert("Es necesario que inicies sesión antes de continuar con esta acción.", "warning", () => navigate("/sign-in", { state: { from: location } }));
        }
    }

    const TiraImagenes = () => {
        const handleZoom = (e, imagen) => {
            e.preventDefault();

            VisorImg({
                preview: imagen,
                name: imagen.split('/').pop()
            });
        }

        return (
            <div className="cont_img_galery">
                {imagenes.map((img, i) => {
                    let _new_file = img.source.uri;

                    return (
                        <img key={i}
                            className="img_galery"
                            src={_new_file}
                            alt="Imagen de la galeria"
                            onClick={e => handleZoom(e, _new_file)}
                        />
                    );
                })}
            </div>
        );
    }

    const Promedio = () => {
        let _stars = [];
        let _entero = Math.floor(calificacion);
        let _decimal = (((Math.round(calificacion * 100)) - (Math.floor(calificacion) * 100)));

        for (let i = 0; i < _entero; i++) {
            _stars.push(<Iconos.StarFull key={i} className="iconStar" />);
        }

        for (let i = _entero; i < 5; i++) {
            if (_decimal > 0) {
                _stars.push(<Iconos.StarMidle key={i} className="iconStar" />);
                _decimal = 0;
            } else {
                _stars.push(<Iconos.Star key={i} className="iconStar" />);
            }
        }

        return (
            <div className="cont_rating">
                {_stars}
                <p>Promedio: <b>{calificacion}</b></p>
            </div>
        );
    }

    const Info = () => {

        return (
            <div className="cont_info">

                <div className="inRow">

                    <div className="cont_descripcion">
                        <h3>Descripción</h3>
                        <p>{data.descripcion}</p>
                    </div>

                </div>

                <div className="inRow">

                    <div className="info_text">
                        <h3>Ciudad</h3>
                        <p>{data.ciudad}</p>
                    </div>

                    <div className="info_text">
                        <h3>Género</h3>
                        <p>{data.genero}</p>
                        {data.subgenero && <p>{data.subgenero}</p>}
                    </div>

                </div>

                <div className="inRow">

                    <div className="info_text">
                        <h3>Músicos</h3>
                        <p>{data.integrantes} - {data.integrantes > 0 && numeroALetras(data.integrantes)}</p>
                    </div>

                    <div className="info_text">
                        <h3>Tamaño del grupo</h3>
                        <p>{data.formato}</p>
                    </div>

                </div>

                <div className="inRow">

                    <div className="info_text">
                        <h3>Disponibilidad</h3>
                        <p>{data.disponibilidad}</p>
                    </div>

                    <div className="info_text">
                        <h3>Voz</h3>
                        <p>{data.voces}</p>
                    </div>

                </div>

            </div>
        );
    }

    const Trayectoria = () => {
        return (
            <div className="cont_info">
                {data.trayectoria ?
                    <div className="inRow" style={{ paddingTop: 32, paddingHorizontal: 12 }}>
                        <p>{data.trayectoria}</p>
                    </div>
                    :
                    <h3>¡No ha agregado su trayectoria!</h3>
                }
            </div>
        );
    }

    const Comentarios = () => {

        return (
            <div className="cont_info">

                <div className="cont_comentario">

                    {comentarios.length < 1 && <h3>¡No hay comentarios!</h3>}

                    {comentarios.map((c, index) => {
                        return (
                            <div className="comentario" key={index}>
                                <h3>{c.nombre_comentador}</h3>
                                <p>{window.atob(c.comentario)}</p>
                                <i>{c.fecha}</i>
                            </div>
                        );
                    })}

                </div>

            </div>
        );
    }

    const ContTab = () => {
        switch (cTab) {
            case 0:
            default:
                return <Info />;
            case 1:
                return <Trayectoria />;
            case 2:
                return <Comentarios />;
        }
    }

    const getInfo = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "mi_perfil",
                propietario: data_user.id
            }
        });

        let _comm = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "comentarios",
                propietario: data_user.id
            }
        });

        if (_data.response == 1) {
            let _promedio = 0;

            if (_comm.data.length > 0) {
                _comm.data.map(c => {
                    _promedio += parseInt(c.puntos);
                });

                _promedio = (_promedio / _comm.data.length).toFixed(1);

                setCalificacion(_promedio);
            }

            _data.data[0].img_perfil = await verificaFile(process.env.REACT_APP_IMAGES + "profile_images/" + _data.data[0].img_perfil);
            setData(_data.data[0]);
            setComentarios(_comm.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in", { state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (data_user.auth && data_user.nivel == 4) {
            getInfo();
        }
        console.log('data_user', data_user);

    }, [data_user]);

    React.useEffect(() => {
        setTitulo("Mi perfil");
    }, []);

    return (
        <div className={typeof android !== "undefined" ? "perfil _movil" : "perfil"}>

            <div className="perfil-contenedor">

                <div className="bkg_perfil">
                    <img src={data.img_perfil} alt={data.nombre_artista} />
                </div>

                <div className="img_perfil" style={{ backgroundImage: "url(" + data.img_perfil + ")" }}>

                    <div className="perfil-informacion">

                        <div className="info_primary">

                            <div className="perfil_btn_task">
                                <Link className="boton" to="/sign-in" state={{ from: location, data: data }}><span>Agregar cuenta</span><Iconos.AddAcount /></Link>
                                <button className="boton"><span>Editar perfil</span><Iconos.Edit /></button>
                            </div>

                            <h1 className="perfil_titulo">{data.nombre_artista}</h1>
                            {data_user.auth && artistas.map((s, i) => {
                                return <h3 className="perfil_account" key={i}>
                                    <InfoPopUp title="Cerrar esta sesión" clases="_flotante">
                                        <Iconos.AccountOff />
                                    </InfoPopUp>

                                    {i !== 0 && <InfoPopUp title="Convertir en principal" clases="_flotante _dos">
                                        <Iconos.AccountConvert />
                                    </InfoPopUp>}

                                    <span>{s.correo}</span><br />{s.nombre}</h3>;
                            })}
                            <Promedio />

                        </div>

                        <div className="cont_tabs">
                            <button className="tab" style={{ backgroundColor: cTab == 0 ? "rgba(255, 255, 255, 0.4)" : "rgba(255, 255, 255, 0.1)" }} onClick={() => setCurrentTab(0)}>Información<Iconos.Info /></button>
                            <button className="tab" style={{ backgroundColor: cTab == 1 ? "rgba(255, 255, 255, 0.4)" : "rgba(255, 255, 255, 0.1)" }} onClick={() => setCurrentTab(1)}>Trayectoria<Iconos.ListUl /></button>
                            <button className="tab" style={{ backgroundColor: cTab == 2 ? "rgba(255, 255, 255, 0.4)" : "rgba(255, 255, 255, 0.1)" }} onClick={() => setCurrentTab(2)}>Comentarios<Iconos.Comments /></button>
                        </div>

                        <div className="cont_tabs_info">
                            <ContTab />
                        </div>

                        <TiraImagenes />

                        <div className="cont_redes_sociales">
                            <h2>Tus redes:</h2>
                            <div className="cont_icon_redes">
                                <a href="/"><Iconos.SvgFacebook /></a>
                                <a href="/"><Iconos.SvgTwitter /></a>
                                <a href="/"><Iconos.SvgInstagram /></a>
                                <a href="/"><Iconos.SvgYoutube /></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showLoading && <Loading />}
        </div>
    )
}

export default withRouter(Perfil);