/* eslint import/no-anonymous-default-export: 0 */

class Log {

    outPut(style, msg, obj) {
        if (typeof android === "undefined" || window?.android?.ref === "twa") {
            obj ?
                console.log(`%c${msg}`, style, obj, '\n\n') :
                console.log(`%c${msg}`, style, '\n\n');
        } else {
            obj ?
                console.log(`${msg}:`, obj) :
                console.log(msg);
        }
    }

    outPutData(msg, style_msg, obj, style_obj) {
        if (typeof android === "undefined" || window?.android?.ref === "twa") {
            obj ?
                console.log(`%c${msg}%c${obj}`, style_msg, style_obj, '\n\n') :
                console.log(`%c${msg}`, style_msg, '\n\n');
        } else {
            obj ?
                console.log(`${msg}:`, obj) :
                console.log(msg);

        }
    }

    /**
     * Salida por consola por defecto
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    d(msg, obj) {
        const Style = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 100, 255)",
            // "border: 1px solid rgb(255, 255, 255)",
            "border-radius: 4px",
            "font-weight: bold",
            "font-size: 0.8rem",
        ];

        const style = Style.join(';') + ';';

        this.outPut(style, msg, obj);
    }

    /**
     * Salida por consola por defecto
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    dm(msg, obj) {
        const StyleMsg = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 100, 255)",
            "border: 1px solid rgb(255, 255, 255)",
            "border-radius: 4px",
            "font-weight: bold",
            "font-size: 0.6rem",
        ];

        const StyleData = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "margin-left: 0.5rem",
            "color: rgb(240, 240, 240)",
            "background-color: rgb(40, 40, 40)",
            "border: 1px solid rgb(240, 240, 240)",
            "border-radius: 4px",
            "font-size: 0.6rem",
            "font-weight: lighter",
            "text-decoration: none",
        ];

        const style_msg = StyleMsg.join(';') + ';';
        const style_data = StyleData.join(';') + ';';

        this.outPutData(msg, style_msg, obj, style_data);
    }

    /**
     * Salida por consola de tipo información
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    i(msg, obj) {
        const Style = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 128, 0)",
            "font-weight: bold",
            "border-radius: 4px",
            "font-size: 0.8rem",
        ];

        const style = Style.join(';') + ';';

        this.outPut(style, msg, obj);
    }

    /**
     * Salida por consola de tipo alerta
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    w(msg, obj) {
        const Style = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(40, 40, 40)",
            "background-color: rgb(0, 255, 0)",
            "font-weight: bold",
            "border-radius: 4px",
            "font-size: 0.8rem",
        ];

        const style = Style.join(';') + ';';

        this.outPut(style, msg, obj);
    }

    /**
     * Salida por consola de tipo alerta
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    wm(msg, obj) {
        const StyleMsg = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(0, 0, 0)",
            // "text-shadow: 0 0 2px rgb(255, 255, 255)",
            "background-color: rgb(0, 255, 0)",
            "border-radius: 4px",
            "font-weight: normal",
            "font-size: 0.6rem",
            "text-decoration: none",
        ];

        const StyleData = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "margin-left: 0.5rem",
            "color: rgb(240, 240, 240)",
            "background-color: rgb(40, 40, 40)",
            "border: 1px solid rgb(240, 240, 240)",
            "border-radius: 4px",
            "font-size: 0.6rem",
            "font-weight: lighter",
            "text-decoration: none",
        ];

        const style_msg = StyleMsg.join(';') + ';';
        const style_data = StyleData.join(';') + ';';

        this.outPutData(msg, style_msg, obj, style_data);
    }

    /**
     * Salida por consola de tipo error
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    e(msg, obj) {
        const Style = [
            "padding: 0.2rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(238, 238, 238)",
            "background-color: rgb(255, 58, 58)",
            "font-weight: bold",
            "border-radius: 4px",
            "font-size: 0.8rem",
        ];

        const style = Style.join(';') + ';';

        this.outPut(style, msg, obj);
    }

    /**
     * Salida por consola de tipo error
     * @param {String} msg El texto a mostrar por consola 
     * @param {*} obj dato a mostrar junto al texto
     */
    title(msg, obj) {
        const Style = [
            "padding: 0.45rem 0.9rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(240, 240, 240)",
            "background-color: #8f1913",
            "border: 1px solid rgb(240, 240, 240)",
            "border-radius: 8px",
            "font-weight: bold",
            "font-size: 1.5rem",
        ];

        const style = Style.join(';') + ';';

        this.outPut(style, msg, obj);
    }
}

export default new Log();