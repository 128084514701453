import * as React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { ChatProvider, MediaProvider } from "./Contexts";
import { useChat, useMedia } from "hooks";
import { ListaChat } from "shared/chat";

import { Header, HeaderMovil } from "shared/header";
import Footer from "shared/footer";

import Inicio from "../views/inicio";
import RecuperarClave from "../views/RecuperarClave";
import CambiarClave from "../views/CambiarClave";
import Registro from "../views/Registro";
import SobreNosotros from "../views/SobreNosotros";
import PorqueNosotros from "../views/PorqueNosotros";
import PoliticasCondiciones from "../views/PoliticasCondiciones";
import Login from "../views/Login";
import NoMatch from "../views/shared/error_404";
import Contactanos from "../views/Contactanos";
import Artista from "../views/Artista";
import Perfil from "../views/Perfil";
import MasArtistas from "../views/inicio/components/MasArtistas";
// import Buscar from "../views/shared/buscar";
import TrabajaConNosotros from "../views/TrabajaConNosotros";
// import Notificaciones from "../views/shared/notificaciones";


const ChatWindow = () => {
    const { chats } = useChat();
    const { data_user } = useMedia();

    return (
        <>
            {data_user.auth &&
                <div className={typeof android !== "undefined" ? "cont_float_bottom _movil" : "cont_float_bottom"}>
                    {chats.map(item => {
                        return item;
                    })}

                    <ListaChat />
                </div>}
        </>
    )
}

const Layout = () => {
    return (
        <>
            {typeof android !== "undefined" ?
                <HeaderMovil />
                :
                <Header />
            }
            {/* <Buscar /> */}
            {/* <Notificaciones/> */}

            <main id="main" className="wrapper">
                <Outlet />
            </main>
        </>
    )
}

const AppRoutes = () => {

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    React.useEffect(() => {
        if (typeof android !== "undefined") {
            document.onselectstart = () => false;
        } else {
            if (process.env.NODE_ENV === 'production') {
                document.addEventListener("contextmenu", handleContextMenu);

                return () => {
                    document.removeEventListener("contextmenu", handleContextMenu);
                }
            }
        }
    }, []);

    return (
        <MediaProvider>
            <ChatProvider>
                <Routes>
                    <Route path="/" element={<Layout />} >
                        <Route path="/" element={<Inicio />} />
                        <Route path="/artista" element={<Artista />} />
                        <Route path="/mi-perfil" element={<Perfil />} />
                        <Route path="/mas-artistas/:genero" element={<MasArtistas />} />
                        <Route path="/contactanos" element={<Contactanos />} />
                        <Route path="/trabaja-con-nosotros" element={<TrabajaConNosotros />} />
                        <Route path="/sobre-nosotros" element={<SobreNosotros />} />
                        <Route path="/porque-nosotros" element={<PorqueNosotros />} />
                        <Route path="/terminos-y-condiciones" element={<PoliticasCondiciones />} />
                        <Route path="/cambiar-clave" element={<CambiarClave />} />
                        <Route path="*" element={<NoMatch />} />
                    </Route>

                    <Route path="/recuperar-clave" element={<RecuperarClave />} />
                    <Route path="/sign-up" element={<Registro />} />
                    <Route path="/sign-in" element={<Login />} />

                    {/* <Route path="/contacto"   element={props => <Contacto {...props} traslate={traslate} />} /> */}
                </Routes>
                <footer>
                    <Footer />
                    <ChatWindow />
                    <div id="modal"></div>
                </footer>
            </ChatProvider>
        </MediaProvider >
    )
}

export default AppRoutes;

// top
// logo
// notificaciones
// buscar