import * as React from 'react';
import { Alert, alerta, alertaRemove } from 'shared/Alert';
import { SharedPreferences } from 'services/Contexts';
import withRouter from 'services/withRouter';
import logo from 'images/logo_blanco.svg'
import * as Iconos from 'images/iconos';
import { Link } from 'react-router-dom';
import Loading from 'shared/Loading';
import { useMedia } from 'hooks';
import api from 'services/api';

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const Login = (router) => {
    const { navigate, location } = router;
    const { data_user, setDataUser, setTitulo } = useMedia();
    const [goTo, setGoTo] = React.useState({ url: "/", data: [] });
    const [showLoading, setShowLoading] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [pass, setPass] = React.useState("");
    const form_ref = React.useRef();


    const goSignUp = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/sign-up", { state: { from: location } });
    }

    const validateFormatEmail = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(email.trim()).toLowerCase())) {
            alerta(form_ref.current.correo, "¡El formato del correo es incorrecto!");

            return false;
        } else {
            return true;
        }
    }

    const validaMailLinked = () => {
        alertaRemove(form_ref.current.correo);

        let _email = data_user.filter(s => s.correo === email.trim());

        if (_email[0]) {
            alerta(form_ref.current.correo, "¡Este correo ya se encuentra vinculado!");
            form_ref.current.correo.focus();

            return false;
        } else {
            return true;
        }
    }

    const validaMail = () => {
        alertaRemove(form_ref.current.correo);

        if (email == null || email.length < 1) {
            alerta(form_ref.current.correo, "¡Debes ingresar tu correo!");
            form_ref.current.correo.focus();

            return false;
        } else {
            return true;
        }
    }

    const validaPass = () => {
        alertaRemove(form_ref.current.clave);

        if (pass == null || pass.length < 1) {
            alerta(form_ref.current.clave, "¡Debes ingresar tu contraseña!");
            form_ref.current.clave.focus();

            return false;
        } else if (pass.length < 8) {
            alerta(form_ref.current.clave, "¡La contraseña es demasiado corta!");

            return false;
        } else {
            return true;
        }
    }

    const validate = async (e) => {
        e.preventDefault();

        if (validaMail()
            && validateFormatEmail()
            //&& validaMailLinked()
            && validaPass()) {
            setShowLoading(true);

            let _data = await api.signIn(email.trim(), pass.trim());

            if (_data.response == 1) {
                let _last_page = new SharedPreferences().getString("last_page") ?? {};
                let _user = _data.data;

                setDataUser(_user);

                if (typeof android !== "undefined") {
                    // eslint-disable-next-line no-undef
                    // android.saveDataLogin(JSON.stringify({ ..._user, correo: email, clave: pass }));
                }

                setTimeout(() => {
                    setShowLoading(false);
                    navigate(_last_page.pathname ?? "/", { replace: true, state: { data: _last_page.data } });
                }, 2000);

            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    React.useEffect(() => {
        setTitulo("Inicio de sesión");
    }, []);

    React.useEffect(() => {
        if (location.state) {
            setGoTo({
                url: location.state.from || "/",
                data: location.state.data || []
            });
        }
    }, [location]);

    return (
        <div className="cont_principal login">

            <div className="cont_form">

                <img src={logo} alt="logo" />

                <form ref={form_ref}>

                    <label>Correo electrónico</label>
                    <div className="inputs">
                        <Iconos.Email />
                        <input type="email" name="correo"
                            value={email} onChange={e => { alertaRemove(form_ref.current.correo); setEmail(e.target.value) }} />
                    </div>

                    <label>Contraseña</label>
                    <div className="inputs">
                        <Iconos.LockOutline />
                        <input type={showPass ? "text" : "password"} name="clave"
                            value={pass} onChange={e => { alertaRemove(form_ref.current.clave); setPass(e.target.value) }} />
                        {showPass ?
                            <Iconos.EyeOff onClick={() => setShowPass(v => !v)} />
                            :
                            <Iconos.Eye onClick={() => setShowPass(v => !v)} />
                        }
                    </div>

                    <Link to="/recuperar-clave">¿Olvidaste tu Contraseña?</Link>
                    <button type="button" className="boton boton-primary" onClick={validate}>INGRESAR</button>
                </form>

                <div className="registrate">

                    <h3>Ó INGRESA CON: </h3>

                    <div className="redes">
                        <Iconos.Facebook />
                        <Iconos.GooglePlus />
                    </div>


                    <h3>¿NO TIENES CUENTA?</h3>
                    <button className="boton boton-secundary" onClick={goSignUp}>REGISTRATE</button>

                    <p>Al ingresar estas aceptando nuestros</p>
                    <Link to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</Link>

                </div>
            </div>

            {showLoading && <Loading />}
        </div>
    )
}

export default withRouter(Login);