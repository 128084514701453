import * as React from "react";
import Carousel from "./components/carousel";
import withRouter from "services/withRouter";
import { useChat, useMedia } from "hooks";
import { Link } from "react-router-dom";
import * as Iconos from "images/iconos";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */

const resaltados = [
    {
        "id_artista": "831",
        "nombre_artista": "Mariachi Real De Mi Tierra",
        "tipo_documento": "Cedula Ciudadana",
        "numero_documento": "79714867",
        "telefono": "3143160664",
        "ciudad": "Cali",
        "genero": "Mariachis",
        "subgenero": null,
        "fecha_solicitud": "2020-08-04 15:26:14",
        "fecha_aprobado": "2020-08-05 07:55:09",
        "website": "undefined",
        "descripcion": "Serenatas presenciales",
        "disponibilidad": "24 horas",
        "latLng": "4.7010, -74.0802",
        "fecha_dispo": null,
        "hora_dispo": null,
        "disponible": "0",
        "aprobado": "1",
        "voces": "Masculina y Femenina",
        "formato": "mediano",
        "integrantes": "8",
        "apikey": null,
        "apilogin": null,
        "merchantid": null,
        "accountId": null,
        "trayectoria": "5 años",
        "destacado": "0",
        "vistas": "0",
        "dummi": "0",
        "valor_servicio": "0",
        "premiun": "-1",
        "representante": null,
        "cola": null,
        "facebook": null,
        "instagram": "https://www.instagram.com/p/CE0mIASJXUL/?igshid=qymqgti9eyuc",
        "tipo_servicio": "1",
        "identificador": "831",
        "correo": "armando.musico79@gmail.com",
        "img_perfil": "https://tuserenatapp.com/api/images/profile_images/PROFILE_831_IMG-20200821-WA0023.jpg",
        "fecha_c_img": null,
        "img_galery1": "MEDIA_831_IMG_20201014_003512_823.jpg",
        "tumb_galery1": "MEDIA_831_IMG_20201014_003512_823.jpg",
        "fecha_c_img1": "1602654263",
        "img_galery2": "MEDIA_831_IMG_20200823_092629_057.jpg",
        "tumb_galery2": "MEDIA_831_IMG_20200823_092629_057.jpg",
        "fecha_c_img2": "1598573227",
        "img_galery3": "MEDIA_831_IMG_20200821_214024_831.jpg",
        "tumb_galery3": "MEDIA_831_IMG_20200821_214024_831.jpg",
        "fecha_c_img3": "1598573304",
        "video_youtube1": "LbC4eX_9BNY",
        "fecha_c_video1": null,
        "video_youtube2": "tYaLaSyLN7Y",
        "fecha_c_video2": null,
        "nombre": "manuel prieto"
    }
];

const Inicio = (router) => {
    const { navigate, location } = router;
    const [generos, setGeneros] = React.useState([]);
    const { data_user, setTitulo } = useMedia();
    const [data, setData] = React.useState([]);
    const { setChaters, addChat } = useChat();


    const handleAddChat = (e) => {
        e.preventDefault();

        if (data_user.auth) {
            addChat(data);
            setChaters(c => {
                let _lement = c.filter(i => i.id_artista === data.id_artista)[0];

                if (_lement) {
                    return c;
                } else {
                    return [...c, data];
                }
            });
        } else {
            Alert("Es necesario que inicies sesión antes de continuar con esta acción.", "warning", () => navigate("/sign-in", { state: { from: location, data: data } }));
        }
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-artistas",
            data: {
                tipo: "generos"
            }
        });

        if (_data.response == 1) {
            setGeneros(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    React.useEffect(() => {
        setTitulo("Tu Serenata");
        getInfo();
        setData(resaltados[0]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_principal">
            <div className="baner_principal">
                <div className="contenedor">
                    <div>
                        <h3 className="titulo">{data.nombre_artista}</h3>
                        <p className="descripcion">{data.descripcion}</p>
                        <button className="boton" onClick={handleAddChat}><Iconos.Comments />Abrir Chat</button>
                        <Link className="boton" to="/artista" state={{ data: data }}><Iconos.Info />Ir a perfil</Link>
                    </div>
                </div>
            </div>

            {generos.map((item, index) => <Carousel key={index} genero={item.genero} {...router} />)}
        </div>
    );
}

export default withRouter(Inicio);