import * as React from 'react';

const InfoPopUp = ({ children, title, clases = "" }) => {
    const [showTitle, setShowTitle] = React.useState(false);

    return (
        <div className={`_info ${clases}`} onMouseEnter={e => setShowTitle(true)} onMouseLeave={e => setShowTitle(false)}>
            {children}

            {showTitle && <h3 className="_title">{title}</h3>}
        </div>
    )
}

export default InfoPopUp;