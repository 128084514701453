/* eslint eqeqeq: 0 */

// import firebase from '@react-native-firebase/app';
const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const numeroALetras = (function () {
    // Código basado en el comentario de @sapienman
    // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        let strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
        let strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles.toLocaleLowerCase();

        return strMillones.toLocaleLowerCase() + ' ' + strMiles.toLocaleLowerCase();
    } //Millones()

    return function NumeroALetras(num, currency = {
        plural: "integrantes",
        singular: "integrante"
    }) {
        currency = currency || {};
        let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || 'integrantes', 
            letrasMonedaSingular: currency.singular || 'integrante',
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'con ' + (function () {
                if (data.centavos == 1)
                    return Millones(data.centavos);
                else
                    return Millones(data.centavos);
            })();
        };

        if (data.enteros == 0)
            return 'cero';
        if (data.enteros == 1)
            return Millones(data.enteros);
        else
            return Millones(data.enteros);
    };

})();

class Funciones {

    /**
     * Verifica en el dispositivo los permisos de firebase messaging
     *
     * @returns {Promise} No retorna ningun valor
     */
    // async requestMessaginPermission() {
    //     const authStatus = await firebase.messaging().requestPermission();
    //     const enabled =
    //         authStatus === firebase.messaging.AuthorizationStatus.AUTHORIZED ||
    //         authStatus === firebase.messaging.AuthorizationStatus.PROVISIONAL;

    //     if (enabled) {
    //         console.log('Messagin Permission:', authStatus);
    //     } else {
    //         console.log('Not Messagin Permission:', authStatus);
    //     }
    // }

    /**
     * Inscribe a un grupo de mensajeria
     * 
     * @param {string} _topic Nombre del grupo
     * 
     * @returns {Promise} No retorna ningun valor
     */
    // async subscribeToTopic(_topic) {
    //     firebase.messaging()
    //         .subscribeToTopic(_topic)
    //         .then(() => console.log('Subscrito a', _topic))
    //         .catch(error => console.log('Error al suscribir a ' + _topic + ':', error));
    // }

    /**
     * Desinscribirse de un grupo de mensajeria
     * 
     * @param {string} _topic Nombre del grupo
     * 
     * @returns {Promise} No retorna ningun valor
     */
    // async unsubscribeFromTopic(_topic) {
    //     firebase.messaging()
    //         .unsubscribeFromTopic(_topic)
    //         .then(() => console.log('Desuscrito de', _topic))
    //         .catch(error => console.log('Error al desuscribir de ' + _topic + ':', error));
    // }

    /**
     * Formatea una fecha en formato dd/mm/yyyy
     * tomando como entrada una fecha en formato unix
     * 
     * @param {String} unix: fecha en formato unix
     * @return {String}: fecha en formato dd/mm/yyyy
     */
    formatFecha(unix) {
        let fecha = new Date(unix * 1000);
        let dia = fecha.getDate();
        let mes = fecha.getMonth(); // + 1;
        let anio = fecha.getFullYear();

        if (dia < 10) {
            dia = '0' + dia;
        }

        // if (mes < 10) {
        //     mes = '0' + mes;
        // }

        return dia + '/' + meses[mes] + '/' + anio;
    }

    /**
     * Formatea una fecha en formato dd/mm/yyyy hh:mm
     * tomando como entrada una fecha en formato unix
     * 
     * @param {String} unix: fecha en formato unix
     * @return {String}: fecha en formato dd/mm/yyyy hh:mm
     */
    formatHora(unix) {
        let fecha = new Date(unix * 1000);
        let dia = fecha.getDate();
        let mes = fecha.getMonth(); // + 1;
        let anio = fecha.getFullYear();
        let hora = fecha.getHours();
        let minutos = fecha.getMinutes();

        if (dia < 10) {
            dia = '0' + dia;
        }

        if (hora < 10) {
            hora = '0' + hora;
        }

        if (minutos < 10) {
            minutos = '0' + minutos;
        }


        return dia + '/' + meses[mes] + '/' + anio + ' ' + hora + ':' + minutos;
    }

    /**
     * Ordena un numero en formato de moneda
     * 
     * @param num {String} numero a formatear
     * @returns {JSON} obj {`int`, `format`}
     */
    formatPrice(num) {
        num += '';
        num = num.replace(/[^0-9\.]/g, '');

        let _clean = num.toString().replaceAll(".", "");
        let _cant = parseInt(_clean.length / 3);
        let _to_return = "";
        let _rest = _clean;
        let _parts = [];

        for (let i = 0; i < _cant; i++) {
            _parts.push(_rest.slice(-3));
            _rest = _rest.slice(0, -3);
        }

        for (let e = _parts.length - 1; e >= 0; e--) {
            _to_return += "." + _parts[e];
        }

        _to_return = _rest + _to_return;
        _to_return = _to_return.slice(0, 1) == "." ? _to_return.slice(1, _to_return.length) : _to_return;

        return {
            int: _clean,
            format: _to_return
        };
    }

    getTiempo(unixCreado, unixActual) {
        let timeActual = new Date();
        unixActual = unixActual || Math.round((timeActual.getTime() + ((timeActual.getTimezoneOffset() / 60) * 3600)) / 1000);
        let tiempo = Math.round((unixActual - unixCreado) / 60);
        let toReturn;

        if (tiempo < 2) {
            toReturn = `Hace instantes`;
        } else if (tiempo >= 2 && tiempo < 60) {
            toReturn = `Hace ${Math.round(tiempo)} minutos`;
        } else if (tiempo >= 60 && tiempo < 120) {
            toReturn = `Hace 1 hora`;
        } else if (tiempo >= 120 && tiempo < 1440) {
            toReturn = `Hace ${Math.round(tiempo / 60)} horas`;
        } else if (tiempo >= 1440 && tiempo < 2880) {
            toReturn = `Hace 1 día`;
        } else if (tiempo >= 2880 && tiempo < 10080) {
            toReturn = `Hace ${Math.round(tiempo / 1440)} días`;
        } else if (tiempo >= 10080 && tiempo < 20160) {
            toReturn = `Hace 1 semana`;
        } else if (tiempo >= 20160 && tiempo < 43200) {
            toReturn = `Hace ${Math.round(tiempo / 10080)} semanas`;
        } else if (tiempo >= 43200 && tiempo < 86400) {
            toReturn = `Hace 1 mes`;
        } else if (tiempo >= 86400 && tiempo < 525960) {
            toReturn = `Hace ${Math.round(tiempo / 43200)} meses`;
        } else if (tiempo >= 525960 && tiempo < 1051920) {
            toReturn = `Hace 1 año`;
        } else if (tiempo >= 1051920) {
            toReturn = `Mas de dos años`;
        }

        return toReturn;
    }

    getTimeAgo(time_msg) {
        let ago_time = "hace instantes";
        let time_current = new Date().getTime() / 1000;
        let tiempoPasado = (time_current - time_msg);

        const round = (num) => {
            return Math.round(num);
        }
        const cleanDot = (num) => {
            return num.toFixed(2).split('.')[0];
        }

        let seconds = 60;
        let mins = seconds * 60;

        if (time_msg !== null) {
            if (tiempoPasado >= seconds && tiempoPasado < mins) {
                ago_time = round(tiempoPasado / seconds) > 1 ? "hace " + round(tiempoPasado / seconds) + " minutos" : "hace " + round(tiempoPasado / seconds) + " minuto";
            } else if (tiempoPasado >= 3600 && tiempoPasado < 86400) {
                ago_time = round(tiempoPasado / 3600) > 1 ? "hace " + cleanDot(tiempoPasado / 3600) + " horas" : "hace " + cleanDot(tiempoPasado / 3600) + " hora";
            } else if (tiempoPasado >= 86400 && tiempoPasado < 604800) {
                ago_time = round(tiempoPasado / 86400) > 1 ? "hace " + round(tiempoPasado / 86400) + " días" : "hace " + round(tiempoPasado / 86400) + " día";
            } else if (tiempoPasado >= 604800 && tiempoPasado < 31536000) {
                ago_time = round(tiempoPasado / 604800) > 1 ? "hace " + round(tiempoPasado / 604800) + " semanas" : "hace " + round(tiempoPasado / 604800) + " semana";
            } else if (tiempoPasado > 31536000) {
                ago_time = "mas de un año";
            }
        } else {
            ago_time = null;
        }

        return ago_time;
    }
}

export { numeroALetras };
export default new Funciones();